import GeneralInfo from './GeneralInfo';
import SiteList from './SiteList';

const DashBoard = () => {
    return (
        <div className="dashboard">
            <GeneralInfo />
            <SiteList />
        </div>
    );
};

export default DashBoard;
