import { createAsyncThunk } from '@reduxjs/toolkit';
import { PROCESS_API } from 'common/constants';
import { API } from 'services';

export const getFTProcesses = createAsyncThunk(
    'processes/getFTProcesses',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.post(`${PROCESS_API}/ft-list`, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getFLSProcesses = createAsyncThunk(
    'processes/getFLSProcesses',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.post(`${PROCESS_API}/fls-list`, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const linkProcess = createAsyncThunk(
    'processes/linkProcess',
    async (item, { rejectWithValue }) => {
        try {
            const { data } = await API.post(`${PROCESS_API}/link`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
