import { createAsyncThunk } from '@reduxjs/toolkit';
import { HEADER_TENANTS_API } from 'common/constants';
import { API } from 'services';

export const getHeaderTenants = createAsyncThunk(
    'tenant/headerTenants',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(HEADER_TENANTS_API, filters);

            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const updateCurrentTenantId = createAsyncThunk(
    'tenant/updateCurrentTenantId',
    (tenantId, { rejectWithValue }) => {
        if (!tenantId) {
            return rejectWithValue('Invalid tenant id');
        }
        return tenantId;
    }
);
