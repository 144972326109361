import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
    cameraSlice,
    companySelectionSlice,
    companySlice,
    companyTypeSelectionSlice,
    engineFisheryBoatSelectionSlice,
    engineServiceBoatSelectionSlice,
    engineSiteSelectionSlice,
    engineSlice,
    enginesSelectionSlice,
    filterSlice,
    fishGroupSlice,
    fisheryBoatSlice,
    flsProcessSlice,
    ftProcessSlice,
    headerTenantSlice,
    onBoardingServiceBoatSlice,
    onBoardingSiteSlice,
    originalBoatSelectionSlice,
    originalSiteSelectionSlice,
    penSelectionSlice,
    penSlice,
    profileSlice,
    serviceBoatSelectionSlice,
    serviceBoatSlice,
    serviceSelectionSlice,
    serviceSlice,
    sitePenSlice,
    siteSelectionSlice,
    siteSlice,
    stockCountSlice,
    tenantSlice,
    tenantTypeSelectionSlice,
    userSlice
} from './slices';

const rootReducer = combineReducers({
    companySelection: companySelectionSlice.reducer,
    companyTypeSelection: companyTypeSelectionSlice.reducer,
    originalSiteSelection: originalSiteSelectionSlice.reducer,
    originalBoatSelection: originalBoatSelectionSlice.reducer,
    serviceSelection: serviceSelectionSlice.reducer,
    engineFisheryBoatSelection: engineFisheryBoatSelectionSlice.reducer,
    engineServiceBoatSelection: engineServiceBoatSelectionSlice.reducer,
    engineSiteSelection: engineSiteSelectionSlice.reducer,
    siteSelection: siteSelectionSlice.reducer,
    serviceBoatSelection: serviceBoatSelectionSlice.reducer,
    enginesSelection: enginesSelectionSlice.reducer,
    filter: filterSlice.reducer,
    sitePen: sitePenSlice.reducer,
    profile: profileSlice.reducer,
    company: companySlice.reducer,
    user: userSlice.reducer,
    site: siteSlice.reducer,
    serviceBoat: serviceBoatSlice.reducer,
    service: serviceSlice.reducer,
    headerTenant: headerTenantSlice.reducer,
    pen: penSlice.reducer,
    fisheryBoat: fisheryBoatSlice.reducer,
    flsProcess: flsProcessSlice.reducer,
    ftProcess: ftProcessSlice.reducer,
    engine: engineSlice.reducer,
    tenant: tenantSlice.reducer,
    tenantTypeSelection: tenantTypeSelectionSlice.reducer,
    onBoardingSite: onBoardingSiteSlice.reducer,
    onBoardingServiceBoat: onBoardingServiceBoatSlice.reducer,
    fishGroup: fishGroupSlice.reducer,
    stockCount: stockCountSlice.reducer,
    penSelection: penSelectionSlice.reducer,
    camera: cameraSlice.reducer
});

const setupStore = (preloadedState) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false // Disable serializable check
            }),
        devTools: process.env.NODE_ENV !== 'production'
    });
};

export default setupStore;
