import { Button, DatePicker, Form, InputNumber, Modal, Spin } from 'antd';
import { FDSelection } from 'common/components';
import { SUCCESSFULLY_EDIT_MESSAGE } from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { moveFishGroup } from 'redux/thunks';

const MoveGroupForm = ({ open = false, fishGroup, onClose = () => {}, onReload = () => {} }) => {
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const upsertLoading = useSelector((s) => s.fishGroup.loading);
    const pensSelection = useSelector((s) => s.penSelection.data);
    const stockCount = useSelector((s) => s.stockCount.data);

    const penNumber = useRef(null);
    const localityNumber = useRef(null);
    const organizationId = useRef(null);

    useEffect(() => {
        form.setFieldValue('totalFishCount', stockCount.count);
    }, [stockCount]);

    const handleMoveFishGroup = async (req) => {
        req.actionDate.startOf('day');

        req.penNumber = penNumber.current;
        req.localityNumber = localityNumber.current;
        req.organizationId = organizationId.current;

        try {
            await dispatch(
                moveFishGroup({
                    id: fishGroup.id,
                    item: req
                })
            ).unwrap();

            alertSuccessMessage(t(SUCCESSFULLY_EDIT_MESSAGE));
            onReload();
            handleCancel();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const handlePenChange = (penId, pen) => {
        penNumber.current = pen?.penNumber;
        localityNumber.current = pen?.localityNumber;
        organizationId.current = pen?.organizationId;
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            title={t('fishGroup.moveGroupForm.title')}
            open={open}
            onCancel={handleCancel}
            onOk={onClose}
            footer={
                <>
                    <Button type="default" onClick={handleCancel} className="m-1">
                        {t('general.form.cancel')}
                    </Button>
                    <Button
                        onClick={form.submit}
                        className="m-1 bg-sky-700 hover:bg-sky-800 text-white hover:text-white"
                    >
                        {t('general.form.saveChange')}
                    </Button>
                </>
            }
        >
            <Spin spinning={upsertLoading}>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={handleMoveFishGroup}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                        label={t('fishGroup.actionDate.title')}
                        name="actionDate"
                        rules={[
                            {
                                required: true,
                                message: t('fishGroup.actionDate.required')
                            }
                        ]}
                    >
                        <DatePicker placeholder={t('fishGroup.actionDate.placeholder')} />
                    </Form.Item>

                    <Form.Item
                        label={t('fishGroup.pen.title')}
                        name="penId"
                        rules={[
                            {
                                required: true,
                                message: t('fishGroup.pen.required')
                            }
                        ]}
                    >
                        <FDSelection
                            placeholder={t('fishGroup.pen.placeholder')}
                            listSelectItem={pensSelection.filter(
                                (pen) =>
                                    !pen.hasFishGroup &&
                                    pen.localityNumber === fishGroup?.localityNumber &&
                                    pen.organizationId === fishGroup?.ownerOrganizationId
                            )}
                            onChange={handlePenChange}
                            allowClear={true}
                        />
                    </Form.Item>

                    <Form.Item label={t('fishGroup.totalFishCount.title')} name="totalFishCount">
                        <InputNumber
                            className="w-full"
                            placeholder={t('fishGroup.totalFishCount.placeholder')}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

MoveGroupForm.propTypes = {
    open: PropTypes.bool,
    fishGroup: PropTypes.object,
    onClose: PropTypes.func,
    onReload: PropTypes.func
};

export default MoveGroupForm;
