import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FDTable } from 'common/components';
import { BUTTON_BLUE_CLASS, Title } from 'common/constants';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    getOnBoardingServiceBoats,
    getOnBoardingSites,
    getOriginalSitesSelection,
    getServicesSelection,
    getTenants,
    getTenantTypesSelection
} from 'redux/thunks';
import TenantForm from './TenantForm';

const Tenant = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [modalState, setModalState] = useState({ open: false, company: null });

    const { data: tenants = [], loading = false } = useSelector((s) => s.tenant);

    const columns = [
        {
            title: 'tenant.tenantTable.tenantName',
            dataIndex: 'tenantName',
            key: 'tenantName'
        },
        {
            title: 'tenant.tenantTable.tenantTypes',
            dataIndex: 'tenantTypes',
            key: 'tenantTypes',
            render: (_, record) =>
                record.tenantTypes?.map((type) => (
                    <p className="m-1" key={type.id}>
                        {type}
                    </p>
                ))
        },
        {
            title: 'tenant.tenantTable.azureTenantId',
            dataIndex: 'azureTenantId',
            key: 'azureTenantId'
        }
    ];

    useEffect(() => {
        dispatch(getTenants());
        dispatch(getOnBoardingSites());
        dispatch(getOnBoardingServiceBoats());
        dispatch(getOriginalSitesSelection());
        dispatch(getServicesSelection());
        dispatch(getTenantTypesSelection());
    }, []);

    const handleClose = () => setModalState({ open: false, company: null });

    const handleShowModal = (company) => setModalState({ open: true, company });

    return (
        <>
            <Title level={4}>{t('tenant.title')}</Title>
            <div>
                <Button className={BUTTON_BLUE_CLASS} onClick={() => handleShowModal(null)}>
                    <PlusOutlined />
                    <span>{t('tenant.tenantForm.buttonTitle')}</span>
                </Button>

                <FDTable headers={columns} data={tenants} loading={loading} rowKey="id" />
            </div>

            <TenantForm open={modalState.open} onClose={handleClose} />
        </>
    );
};

export default Tenant;
