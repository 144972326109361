import { AlignLeftOutlined } from '@ant-design/icons';
import { Layout as LibLayout } from 'antd';
import { FDGlobalBoundaryError } from 'common/components';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import get from 'lodash/get';
import { useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import './Layout.scss';
import Sidebar from './Sidebar';

const { Content } = LibLayout;

const Layout = () => {
    const [collapsed] = useState(false);
    const { data: tenantInfo = {} } = useSelector((s) => s.headerTenant);

    const containerStyle = useMemo(() => {
        return { marginLeft: collapsed ? 80 : 250 };
    }, [collapsed]);

    const ldProviderConfig = {
        clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
        context: {
            kind: 'multi',
            Tenant: {
                key: get(tenantInfo, 'currentTenantId', ''),
                name: 'Tenant'
            }
        }
    };

    const handleToggleMenu = (isToggleIcon = false) => {
        const sidebar = document.getElementsByClassName('sidebar')[0];
        const container = document.getElementsByClassName('main')[0];

        if (sidebar.classList.contains('sidebar-responsive')) {
            sidebar.classList.remove('sidebar-responsive');
            container.classList.remove('main-blur');
        } else if (isToggleIcon) {
            sidebar.classList.add('sidebar-responsive');
            container.classList.add('main-blur');
        }
    };

    const renderLayout = () => {
        return (
            <LibLayout hasSider className="layout min-h-screen">
                <Sidebar />

                <AlignLeftOutlined className="toggle-icon" onClick={() => handleToggleMenu(true)} />

                <LibLayout
                    className="layout-container"
                    style={containerStyle}
                    onClick={() => handleToggleMenu()}
                >
                    <Header />

                    <Content className="main pt-3">
                        <ErrorBoundary FallbackComponent={FDGlobalBoundaryError}>
                            <Outlet />
                        </ErrorBoundary>
                    </Content>

                    <Footer />
                </LibLayout>
            </LibLayout>
        );
    };

    const LDProvider = withLDProvider(ldProviderConfig)(renderLayout);

    return <LDProvider />;
};
export default Layout;
