import { createSlice } from '@reduxjs/toolkit';
import { getUsers, updateUser } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: {
        [getUsers.pending]: (state) => {
            state.loading = true;
        },
        [getUsers.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        [getUsers.rejected]: (state) => {
            state.loading = false;
        },

        [updateUser.pending]: (state) => {
            state.loading = true;
        },
        [updateUser.fulfilled]: (state) => {
            state.loading = false;
        },
        [updateUser.rejected]: (state) => {
            state.loading = false;
        }
    }
});
