import { createSlice } from '@reduxjs/toolkit';
import { createSite, deleteSite, getSites, restoreSite, updateSite } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const siteSlice = createSlice({
    name: 'site',
    initialState,
    reducers: {},
    extraReducers: {
        [getSites.pending]: (state) => {
            state.loading = true;
        },
        [getSites.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        [getSites.rejected]: (state) => {
            state.loading = false;
        },

        [createSite.pending]: (state) => {
            state.loading = true;
        },
        [createSite.fulfilled]: (state) => {
            state.loading = false;
        },
        [createSite.rejected]: (state) => {
            state.loading = false;
        },

        [updateSite.pending]: (state) => {
            state.loading = true;
        },
        [updateSite.fulfilled]: (state) => {
            state.loading = false;
        },
        [updateSite.rejected]: (state) => {
            state.loading = false;
        },

        [deleteSite.pending]: (state) => {
            state.loading = true;
        },
        [deleteSite.fulfilled]: (state) => {
            state.loading = false;
        },
        [deleteSite.rejected]: (state) => {
            state.loading = false;
        },

        [restoreSite.pending]: (state) => {
            state.loading = true;
        },
        [restoreSite.fulfilled]: (state) => {
            state.loading = false;
        },
        [restoreSite.rejected]: (state) => {
            state.loading = false;
        }
    }
});
