import { createSlice } from '@reduxjs/toolkit';
import { getFTProcesses, linkProcess } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const ftProcessSlice = createSlice({
    name: 'ftProcess',
    initialState,
    reducers: {},
    extraReducers: {
        [getFTProcesses.pending]: (state) => {
            state.loading = true;
        },
        [getFTProcesses.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        [getFTProcesses.rejected]: (state) => {
            state.loading = false;
        },

        [linkProcess.pending]: (state) => {
            state.loading = true;
        },
        [linkProcess.fulfilled]: (state) => {
            state.loading = false;
        },
        [linkProcess.rejected]: (state) => {
            state.loading = false;
        }
    }
});
