import { EditOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Row, Space } from 'antd';
import Search from 'antd/es/input/Search';
import defaultAvatar from 'assets/Default_Avatar.png';
import { FDMultipleSelection, FDTable } from 'common/components';
import { BUTTON_GRAY_CLASS, Title } from 'common/constants';
import { isMatchSearchText } from 'common/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCompaniesSelection, getCompanyTypesSelection, getUsers } from 'redux/thunks';
import UserForm from './UserForm';

const User = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { data: users, loading } = useSelector((s) => s.user);
    const companiesSelection = useSelector((s) => s.companySelection.data);
    const companyTypesSelection = useSelector((s) => s.companyTypeSelection.data);

    const [searchText, setSearchText] = useState('');
    const [selectedCompanyTypes, setSelectedCompanyTypes] = useState([]);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [modalState, setModalState] = useState({ open: false, user: null });

    const columns = [
        {
            title: 'user.userTable.name',
            dataIndex: 'name',
            key: 'name',
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
                multiple: 1
            },
            sortDirections: ['ascend', 'descend'],
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
            render: (_, record) => (
                <>
                    <Avatar.Group>
                        <Avatar
                            className="shape-avatar"
                            src={record.avatar || defaultAvatar}
                            size={55}
                            alt=""
                        />
                        <div className="avatar-info ml-2">
                            <Title level={5}>{record.name}</Title>
                            <p>{record.email || 'Email address'}</p>
                        </div>
                    </Avatar.Group>
                </>
            )
        },
        {
            title: 'user.userTable.company',
            dataIndex: 'company',
            render: (_, record) => record.company?.name
        },
        {
            title: 'user.userTable.phoneNumber',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber'
        },
        {
            title: 'user.userTable.address',
            dataIndex: 'address',
            key: 'address'
        },
        {
            title: 'user.userTable.action',
            dataIndex: 'Action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        className={BUTTON_GRAY_CLASS}
                        onClick={() => setModalState({ open: true, user: record })}
                    >
                        <EditOutlined />
                        <span>{t('user.userTable.edit')}</span>
                    </Button>
                </Space>
            )
        }
    ];

    useEffect(() => {
        dispatch(getUsers());
        dispatch(getCompaniesSelection({}));
        dispatch(getCompanyTypesSelection());
    }, []);

    const handleCompanyTypesChange = (selectedCompanyTypes) => {
        setSelectedCompanyTypes(selectedCompanyTypes);

        if (selectedCompanyTypes.length)
            setSelectedCompanies(
                companiesSelection
                    .filter(
                        (company) =>
                            selectedCompanies.includes(company.id) &&
                            company.companyTypes.some((ct) => selectedCompanyTypes.includes(ct))
                    )
                    .map((company) => company.id)
            );
    };

    return (
        <div className="user-management">
            <Title level={4}>{t('user.title')}</Title>

            <Row className="md:text-base filter">
                <Col xs={24} lg={12} xl={7} xxl={5}>
                    <div className="font-semibold">{t('user.filterBar.name.title')}</div>
                    <Search
                        placeholder={t('user.filterBar.name.placeholder')}
                        onSearch={(searchText) =>
                            setSearchText(searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
                        }
                    />
                </Col>

                <Col xs={24} lg={12} xl={7} xxl={5}>
                    <div className="font-semibold">{t('user.filterBar.companyTypes.title')}</div>
                    <FDMultipleSelection
                        placeholder={t('user.filterBar.companyTypes.placeholder')}
                        listSelectItem={companyTypesSelection}
                        onChange={handleCompanyTypesChange}
                        dataTestId="original-site-option"
                    />
                </Col>

                <Col xs={24} lg={12} xl={7} xxl={5}>
                    <div className="font-semibold">{t('general.filterBar.company')}</div>
                    <FDMultipleSelection
                        placeholder={t('general.filterBar.companiesPlaceholder')}
                        listSelectItem={companiesSelection.filter(
                            ({ companyTypes }) =>
                                !selectedCompanyTypes.length ||
                                companyTypes.some((ct) => selectedCompanyTypes.includes(ct))
                        )}
                        value={selectedCompanies}
                        onChange={(companyIds) => setSelectedCompanies(companyIds)}
                        dataTestId="original-site-option"
                    />
                </Col>
            </Row>

            <FDTable
                headers={columns}
                data={users.filter(
                    (user) =>
                        (isMatchSearchText(user.name, searchText) ||
                            isMatchSearchText(user.email, searchText) ||
                            isMatchSearchText(user.phoneNumber, searchText)) &&
                        (!selectedCompanies.length ||
                            selectedCompanies.includes(user.organizationId)) &&
                        (!selectedCompanyTypes.length ||
                            user.company?.companyTypes?.some((ct) =>
                                selectedCompanyTypes.includes(ct)
                            ))
                )}
                loading={loading}
                rowKey="id"
            />

            <UserForm
                open={modalState.open}
                user={modalState.user}
                onClose={() => setModalState({ open: false, user: null })}
            />
        </div>
    );
};

export default User;
