import { useMsal } from '@azure/msal-react';
import { GLOBAL_ADMINISTRATOR, NOT_ALLOWED_PAGE } from 'common/constants';
import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { TenantRoute, routes } from 'routes';
import './index.scss';

const App = () => {
    const { pathname } = useLocation();
    const { accounts } = useMsal();
    const navigate = useNavigate();

    useEffect(() => {
        if (!accounts?.length) {
            return;
        }
        const roles = accounts[0]?.idTokenClaims?.roles;

        if (!roles.includes(GLOBAL_ADMINISTRATOR)) {
            navigate(NOT_ALLOWED_PAGE);
        }
    }, [accounts]);

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'
        });
    }, [pathname]);

    const renderRoute = (route, key, renderRoute = () => {}) => (
        <Route
            path={route.path}
            key={key}
            element={
                route?.isNeedTenant ? (
                    <TenantRoute path={route.path}>{route.element}</TenantRoute>
                ) : (
                    route.element
                )
            }
        >
            {route.children &&
                route.children.map((child, index) =>
                    renderRoute(child, `${key}_${index}`, renderRoute)
                )}
        </Route>
    );

    return <Routes>{routes.map((route, index) => renderRoute(route, index, renderRoute))}</Routes>;
};

export default App;
