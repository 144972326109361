export const SITE_API = 'api/sites';

export const PEN_API = 'api/pens';

export const BOAT_API = 'api/boats';

export const PROCESS_API = 'api/processes';

// api endpoint for company
export const COMPANY_API = 'api/companies';

// api endpoint for user
export const USER_API = 'api/users';

// api endpoint for site
export const SITES_CONTAINING_PENS_API = 'api/sites/list';

// api endpoint for service boat
export const SERVICE_BOAT_API = 'api/service-boats';

// api endpoint for selection
export const COMPANY_TYPES_SELECTION_API = 'api/selection/company-types';
export const ORIGINAL_SITES_SELECTION_API = 'api/selection/original-sites';
export const ORIGINAL_BOATS_SELECTION_API = 'api/selection/original-boats';
export const SITES_SELECTION_API = 'api/selection/sites';
export const PEN_SELECTION_API = 'api/selection/pens';
export const SERVICES_SELECTION_API = 'api/selection/services';
export const SERVICE_BOATS_SELECTION_API = 'api/selection/service-boats';
export const COMPANIES_SELECTION_API = 'api/selection/companies';
export const ENGINE_FISHERY_BOAT_SELECTION_API = 'api/selection/engine-fishery-boats';
export const ENGINE_SERVICE_BOAT_SELECTION_API = 'api/selection/engine-service-boats';
export const ENGINE_SITE_SELECTION_API = 'api/selection/engine-sites';
export const TENANT_TYPES_SELECTION_API = 'api/selection/tenant-types';
export const ENGINE_SELECTION_API = 'api/selection/engines';

// api endpoint for profile
export const PROFILE_API = 'api/me/profile';

// api endpoint for service
export const SERVICE_API = 'api/services';
// api endpoint for header tenants
export const HEADER_TENANTS_API = 'api/public/header-tenants';

// api endpoint for fishery boat
export const FISHERY_BOAT_API = 'api/fishery-boats';

// api endpoint for engine
export const ENGINE_API = 'api/engines';

// api endpoint for tenant
export const TENANT_API = 'api/tenants';

// api endpoint for fish groups
export const FISH_GROUP_API = 'api/fish-groups';

// api endpoint for camera
export const CAMERA_API = 'api/cameras';
