import { Card, Col, Image, Row, Spin } from 'antd';
import defaultCompanyLogo from 'assets/Default_Company_Logo.jpg';
import PropTypes from 'prop-types';
import './FDGeneralInfo.scss';

const FDGeneralInfo = ({ loading = false, logo, infoList = [] }) => {
    return (
        <div className="general-info">
            <Spin spinning={loading}>
                <Card bordered className="text-base">
                    <Row gutter={8}>
                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={7}
                            xl={5}
                            xxl={4}
                            className="flex justify-center items-center avatar"
                        >
                            <Image
                                className="rounded-sm border-inherit object-contain"
                                style={{ border: '1px #c7c9cb solid' }}
                                width={150}
                                height={150}
                                alt="Company logo"
                                src={logo || defaultCompanyLogo}
                            />
                        </Col>

                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={17}
                            xl={17}
                            xxl={12}
                            className="flex items-center"
                        >
                            <div className="w-full">
                                <Row gutter={8}>
                                    {infoList.map((info, index) => {
                                        return (
                                            <Col
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={8}
                                                xxl={8}
                                                className="py-4"
                                                key={index}
                                            >
                                                <div className="font-semibold">{info.key}</div>
                                                <div>{info?.value}</div>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Spin>
        </div>
    );
};

FDGeneralInfo.propTypes = {
    loading: PropTypes.bool,
    logo: PropTypes.string,
    status: PropTypes.string,
    infoList: PropTypes.array
};

export default FDGeneralInfo;
