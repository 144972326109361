import { createAsyncThunk } from '@reduxjs/toolkit';
import { CAMERA_API } from 'common/constants';
import { API } from 'services';

export const getCameras = createAsyncThunk(
    'engine/getCameras',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.get(CAMERA_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const createCamera = createAsyncThunk(
    'engine/createCamera',
    async (item, { rejectWithValue }) => {
        try {
            const { data } = await API.post(CAMERA_API, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const updateCamera = createAsyncThunk(
    'engine/updateCamera',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${CAMERA_API}/${id}`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const deleteCamera = createAsyncThunk(
    'engine/deleteCamera',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.delete(`${CAMERA_API}/${id}`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const resetCamera = createAsyncThunk(
    'engine/resetCamera',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${CAMERA_API}/reset/${id}`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
