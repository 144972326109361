import { Button, Form, Input, Modal, Spin } from 'antd';
import { FDUploadImage } from 'common/components';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { updateCurrentProfile, getCurrentProfile } from 'redux/thunks';
import { alertErrorMessage, alertSuccessMessage, normFile } from 'common/utils';
import { SUCCESSFULLY_UPDATE_MESSAGE } from 'common/constants';

const UpdateProfile = ({ open = false, onClose = () => {} }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const { data: profile = {}, loading = false } = useSelector((s) => s.profile);

    useEffect(() => {
        form.setFieldsValue({ ...profile, company: profile.company?.name });
    }, [profile]);

    const handleUpdateProfile = async (req) => {
        let profileInfo = {
            avatar: req.avatar,
            name: req.name,
            bio: req.bio,
            email: req.email,
            phoneNumber: req.phoneNumber,
            address: req.address
        };

        try {
            await dispatch(updateCurrentProfile(profileInfo)).unwrap();

            onClose();
            form.resetFields();
            dispatch(getCurrentProfile());
            alertSuccessMessage(t(SUCCESSFULLY_UPDATE_MESSAGE));
        } catch (error) {
            alertErrorMessage(error, t);
        }
    };

    const handleUploadAvatar = (url) => {
        form.setFieldValue('avatar', url);
    };

    return (
        <Modal
            title={t('profile.updatedForm.title')}
            open={open}
            onCancel={onClose}
            onOk={onClose}
            width={580}
            footer={[
                <Button key="cancel" type="default" onClick={onClose} className="m-1">
                    {t('profile.updatedForm.cancel')}
                </Button>,
                <Button
                    key="update"
                    onClick={form.submit}
                    className="m-1 bg-sky-700 hover:bg-sky-800 text-white hover:text-white"
                    loading={loading}
                >
                    {t('profile.updatedForm.update')}
                </Button>
            ]}
        >
            <Spin spinning={loading}>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={handleUpdateProfile}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                        label={t('profile.updatedForm.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: t('profile.updatedForm.rules.requiredName')
                            }
                        ]}
                    >
                        <Input placeholder={t('profile.updatedForm.name') + '...'} />
                    </Form.Item>

                    <Form.Item label={t('profile.updatedForm.bio')} name="bio">
                        <Input placeholder={t('profile.updatedForm.bio') + '...'} />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label={t('profile.updatedForm.email')}
                        rules={[
                            {
                                type: 'email',
                                message: t('profile.updatedForm.rules.invalidEmail')
                            },
                            {
                                required: true,
                                message: t('profile.updatedForm.rules.requiredEmail')
                            }
                        ]}
                    >
                        <Input
                            placeholder={t('profile.updatedForm.email') + '...'}
                            disabled={true}
                        />
                    </Form.Item>

                    <Form.Item name="company" label={t('profile.updatedForm.company')}>
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item label={t('profile.updatedForm.address')} name="address">
                        <Input placeholder={t('profile.updatedForm.address') + '...'} />
                    </Form.Item>

                    <Form.Item
                        name="phoneNumber"
                        label={t('profile.updatedForm.phoneNumber')}
                        rules={[
                            {
                                pattern: '^(0047|\\+47|47)?[2-9]\\d{7}$',
                                message: t('profile.updatedForm.rules.invalidPhoneNumber')
                            }
                        ]}
                    >
                        <Input
                            style={{
                                width: '100%'
                            }}
                            placeholder={t('profile.updatedForm.phoneNumber') + '...'}
                        />
                    </Form.Item>

                    <Form.Item
                        name="avatar"
                        label={t('profile.updatedForm.avatar')}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        extra="*.png, *.jpg"
                    >
                        <FDUploadImage
                            isAvatar={true}
                            uploadedHandler={handleUploadAvatar}
                            url={profile.avatar}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

UpdateProfile.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};

export default UpdateProfile;
