import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, InputNumber, Modal, Space, Spin } from 'antd';
import { FDSelection } from 'common/components';
import { SUCCESSFULLY_EDIT_MESSAGE } from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { divideFishGroup } from 'redux/thunks';
import './DivideGroupForm.scss';

const DivideGroupForm = ({ open = false, fishGroup, onClose = () => {}, onReload = () => {} }) => {
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const upsertLoading = useSelector((s) => s.fishGroup.loading);
    const pensSelection = useSelector((s) => s.penSelection.data);
    const stockCount = useSelector((s) => s.stockCount.data);

    const penDivides = Form.useWatch('penDivides', form);

    useEffect(() => {
        form.setFieldsValue({
            penDivides: penDivides?.map((penDivide) => ({
                ...penDivide,
                totalFishCount: Math.floor(stockCount.count / penDivides?.length)
            }))
        });
    }, [stockCount, penDivides?.length]);

    const handleDivideFishGroup = async (req) => {
        req.actionDate.startOf('day');

        req.penDivides = req.penDivides.map((penDivide) => {
            const penSelected = pensSelection.find((element) => element.id === penDivide.penId);

            return {
                localityNumber: penSelected.localityNumber,
                penNumber: penSelected.penNumber,
                organizationId: penSelected.organizationId,
                totalFishCount: penDivide.totalFishCount
            };
        });

        try {
            await dispatch(
                divideFishGroup({
                    id: fishGroup.id,
                    item: req
                })
            ).unwrap();

            alertSuccessMessage(t(SUCCESSFULLY_EDIT_MESSAGE));
            onReload();
            handleCancel();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            title={t('fishGroup.divideGroupForm.title')}
            width={800}
            open={open}
            onCancel={handleCancel}
            onOk={onClose}
            footer={
                <>
                    <Button type="default" onClick={handleCancel} className="m-1">
                        {t('general.form.cancel')}
                    </Button>
                    <Button
                        onClick={form.submit}
                        className="m-1 bg-sky-700 hover:bg-sky-800 text-white hover:text-white"
                    >
                        {t('general.form.saveChange')}
                    </Button>
                </>
            }
        >
            <Spin spinning={upsertLoading}>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={handleDivideFishGroup}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                        label={t('fishGroup.actionDate.title')}
                        name="actionDate"
                        rules={[
                            {
                                required: true,
                                message: t('fishGroup.actionDate.required')
                            }
                        ]}
                    >
                        <DatePicker placeholder={t('fishGroup.actionDate.placeholder')} />
                    </Form.Item>

                    <Form.Item label={t('fishGroup.divideGroupForm.pens')}>
                        <Form.List
                            name="penDivides"
                            initialValue={[
                                { penId: null, totalFishCount: null },
                                { penId: null, totalFishCount: null }
                            ]}
                        >
                            {(subFields, subOpt) => (
                                <>
                                    {subFields.map((subField) => (
                                        <Space
                                            key={subField.key}
                                            className="pen-divide-space w-full"
                                            align="baseline"
                                        >
                                            <Form.Item
                                                name={[subField.name, 'penId']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('fishGroup.pen.required')
                                                    },
                                                    {
                                                        validator: async (_, penId) => {
                                                            if (
                                                                penId &&
                                                                penDivides.filter(
                                                                    (item) => item?.penId === penId
                                                                ).length > 1
                                                            )
                                                                return Promise.reject(
                                                                    new Error(
                                                                        t('fishGroup.pen.duplicate')
                                                                    )
                                                                );
                                                        }
                                                    }
                                                ]}
                                            >
                                                <FDSelection
                                                    placeholder={t('fishGroup.pen.placeholder')}
                                                    listSelectItem={pensSelection.filter(
                                                        (pen) =>
                                                            (fishGroup?.penNumber ===
                                                                pen.penNumber ||
                                                                !pen.hasFishGroup) &&
                                                            pen.localityNumber ===
                                                                fishGroup?.localityNumber &&
                                                            pen.organizationId ===
                                                                fishGroup?.ownerOrganizationId
                                                    )}
                                                    onChange={() => form.validateFields()}
                                                />
                                            </Form.Item>

                                            <Form.Item name={[subField.name, 'totalFishCount']}>
                                                <InputNumber
                                                    className="w-full"
                                                    placeholder={t(
                                                        'fishGroup.totalFishCount.placeholder'
                                                    )}
                                                />
                                            </Form.Item>

                                            <MinusCircleOutlined
                                                onClick={() =>
                                                    penDivides?.length > 2 &&
                                                    subOpt.remove(subField.name)
                                                }
                                                hidden={penDivides?.length <= 2}
                                            />
                                        </Space>
                                    ))}

                                    {penDivides?.length < 9 && (
                                        <Button
                                            type="dashed"
                                            onClick={() => subOpt.add()}
                                            block
                                            icon={<PlusOutlined />}
                                        >
                                            {t('fishGroup.divideGroupForm.addSubItem')}
                                        </Button>
                                    )}
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

DivideGroupForm.propTypes = {
    open: PropTypes.bool,
    fishGroup: PropTypes.object,
    onClose: PropTypes.func,
    onReload: PropTypes.func
};

export default DivideGroupForm;
