import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENGINE_API } from 'common/constants';
import { API } from 'services';

export const getEngines = createAsyncThunk(
    'engine/getEngines',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.get(ENGINE_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const createEngine = createAsyncThunk(
    'engine/createEngine',
    async (item, { rejectWithValue }) => {
        try {
            const { data } = await API.post(ENGINE_API, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const updateEngine = createAsyncThunk(
    'engine/updateEngine',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${ENGINE_API}/${id}`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const deleteEngine = createAsyncThunk(
    'engine/deleteEngine',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.delete(`${ENGINE_API}/${id}`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
