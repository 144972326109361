import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Space } from 'antd';
import defaultLogo from 'assets/Default_Logo.png';
import { FDTable } from 'common/components';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_GRAY_CLASS,
    BUTTON_GREEN_CLASS,
    SUCCESSFULLY_DELETION_MESSAGE,
    Title
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { deleteService, getServices } from 'redux/thunks';
import Swal from 'sweetalert2';
import ServiceForm from './ServiceForm';

const Service = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { data: services = [], loading = false } = useSelector((s) => s.service || {});

    const [modalState, setModalState] = useState({ open: false, service: null });

    const columns = [
        {
            title: 'service.serviceTable.service',
            dataIndex: 'name',
            key: 'name',
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
                multiple: 1
            },
            sortDirections: ['ascend', 'descend'],
            render: (_, record) => (
                <Avatar.Group>
                    <img
                        className="object-contain rounded-full"
                        src={record.logo || defaultLogo}
                        width="60vw"
                        height="60vh"
                        data-testid="service-logo"
                        alt="Service logo"
                    />
                    <div className="avatar-info ml-2">
                        <Title level={5}>{record.name}</Title>
                        <p>{record.companyOwner || 'Company owner'}</p>
                    </div>
                </Avatar.Group>
            )
        },
        {
            title: 'service.serviceTable.companies',
            dataIndex: 'companies',
            key: 'companies',
            render: (_, record) =>
                record.companies?.map((company, index) => (
                    <p key={index} className="mb-4">
                        {company}
                    </p>
                ))
        },
        {
            title: 'service.serviceTable.status',
            dataIndex: 'status',
            key: 'status',
            sorter: {
                compare: (a, b) => a.status.localeCompare(b.status),
                multiple: 2
            },
            sortDirections: ['ascend', 'descend'],
            render: (_, record) =>
                record.status && <p>{t(`service.serviceStatus.${record.status.toLowerCase()}`)}</p>
        },
        {
            title: 'service.serviceTable.description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'service.serviceTable.url',
            dataIndex: 'url',
            key: 'url'
        },
        {
            title: 'service.serviceTable.action',
            dataIndex: 'Action',
            render: (_, record) => (
                <Space size="middle">
                    <>
                        <Button
                            className={BUTTON_GRAY_CLASS}
                            onClick={() => handleShowModal(record)}
                        >
                            <EditOutlined />
                            <span>{t('general.action.edit')}</span>
                        </Button>
                        <Button
                            className={BUTTON_GREEN_CLASS}
                            onClick={() => handleDelete(record?.id)}
                        >
                            <DeleteOutlined />
                            <span>{t('general.action.delete')}</span>
                        </Button>
                    </>
                </Space>
            )
        }
    ];

    useEffect(() => {
        dispatch(getServices());
    }, []);

    const handleClose = () => setModalState({ open: false, service: null });

    const handleShowModal = (service) => setModalState({ open: true, service });

    const handleDelete = (id = null) => {
        Swal.fire({
            title: t('general.action.deleteModalWithoutRevert.title'),
            text: t('general.action.deleteModalWithoutRevert.text'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0369a1',
            cancelButtonColor: '#64748b',
            confirmButtonText: t('general.action.deleteModalWithoutRevert.confirmText'),
            cancelButtonText: t('general.action.deleteModal.cancelText')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(deleteService(id)).unwrap();

                    dispatch(getServices());
                    alertSuccessMessage(t(SUCCESSFULLY_DELETION_MESSAGE));
                } catch (err) {
                    alertErrorMessage(err, t);
                }
            }
        });
    };

    return (
        <>
            <Title level={4}>{t('service.title')}</Title>
            <div>
                <Button className={BUTTON_BLUE_CLASS} onClick={() => handleShowModal(null)}>
                    <PlusOutlined />
                    <span>{t('service.newServiceBtn')}</span>
                </Button>

                <FDTable headers={columns} data={services} loading={loading} rowKey="id" />
            </div>

            <ServiceForm
                open={modalState.open}
                service={modalState.service}
                onClose={handleClose}
            />
        </>
    );
};

export default Service;
