import { createAsyncThunk } from '@reduxjs/toolkit';
import { FISH_GROUP_API } from 'common/constants';
import { API } from 'services';

export const getFishGroups = createAsyncThunk(
    'fishGroups/getFishGroups',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(FISH_GROUP_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const addFishGroup = createAsyncThunk(
    'fishGroups/addFishGroup',
    async (item, { rejectWithValue }) => {
        try {
            const { data } = await API.post(FISH_GROUP_API, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const updateFishGroup = createAsyncThunk(
    'fishGroups/updateFishGroup',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${FISH_GROUP_API}/${id}`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const divideFishGroup = createAsyncThunk(
    'fishGroups/divideFishGroup',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${FISH_GROUP_API}/${id}/divide`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const moveFishGroup = createAsyncThunk(
    'fishGroups/moveFishGroup',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${FISH_GROUP_API}/${id}/move`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const releaseFishGroup = createAsyncThunk(
    'fishGroups/releaseFishGroup',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${FISH_GROUP_API}/${id}/release`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const deleteFishGroup = createAsyncThunk(
    'fishGroups/deleteFishGroup',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.delete(`${FISH_GROUP_API}/${id}`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getStockCount = createAsyncThunk(
    'reports/getStockCount',
    async (lTreePath, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${FISH_GROUP_API}/stock-count/${lTreePath}`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
