import { createSlice } from '@reduxjs/toolkit';
import { getTenantTypesSelection } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const tenantTypeSelectionSlice = createSlice({
    name: 'tenantTypeSelection',
    initialState,
    reducers: {},
    extraReducers: {
        [getTenantTypesSelection.pending]: (state) => {
            state.loading = true;
        },
        [getTenantTypesSelection.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload || [];
        },
        [getTenantTypesSelection.rejected]: (state) => {
            state.loading = false;
        }
    }
});
