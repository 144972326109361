import { Button, Form, Input, Modal, Spin } from 'antd';
import { FDSelection } from 'common/components';
import { ENGINE_LOCATION, TextArea } from 'common/constants';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    getEngineSiteSelection,
    getEngineServiceBoatSelection,
    getEngineFisheryBoatSelection
} from 'redux/thunks';

const EngineForm = ({
    location = null,
    engine,
    onCreate = async () => {},
    onUpdate = async () => {},
    onClose = () => {}
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const upsertLoading = useSelector((s) => s.engine.loading);
    const engineSiteSelection = useSelector((s) => s.engineSiteSelection.data);
    const engineServiceBoatSelection = useSelector((s) => s.engineServiceBoatSelection.data);
    const engineFisheryBoatSelection = useSelector((s) => s.engineFisheryBoatSelection.data);

    const isUpdateForm = !!engine;

    useEffect(() => {
        dispatch(getEngineSiteSelection());
        dispatch(getEngineServiceBoatSelection());
        dispatch(getEngineFisheryBoatSelection());
    }, []);

    useEffect(() => {
        if (isUpdateForm) {
            // format configuration json
            const configurationObj = JSON.parse(engine.configurationJson);
            form.setFieldsValue({
                ...engine,
                configurationJson: JSON.stringify(configurationObj, null, 2)
            });
        } else {
            form.resetFields();
        }
    }, [engine]);

    const createOrUpdateEngine = async (req) => {
        if (isUpdateForm) {
            await onUpdate(engine.id, req);
        } else {
            await onCreate({
                ...req,
                engineLocation: location
            });
        }

        form.resetFields();
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <div>
            <Modal
                title={
                    isUpdateForm
                        ? t('engine.engineForm.updateEngine')
                        : t('engine.engineForm.createEngine')
                }
                open={!!location}
                onCancel={handleCancel}
                onOk={onClose}
                width={800}
                footer={
                    <>
                        <Button type="default" onClick={handleCancel} className="m-1">
                            {t('general.form.cancel')}
                        </Button>
                        <Button
                            onClick={form.submit}
                            className="m-1 bg-sky-700 hover:bg-sky-800 text-white hover:text-white"
                        >
                            {isUpdateForm ? t('general.form.saveChange') : t('general.form.create')}
                        </Button>
                    </>
                }
            >
                <Spin spinning={upsertLoading}>
                    <Form
                        name="basic"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 16 }}
                        onFinish={createOrUpdateEngine}
                        autoComplete="off"
                        form={form}
                        initialValues={{
                            configurationJson: '{}'
                        }}
                    >
                        <Form.Item
                            name="name"
                            label={t('engine.engineForm.name.title')}
                            rules={[
                                {
                                    required: true,
                                    message: t('engine.engineForm.name.required')
                                }
                            ]}
                        >
                            <Input placeholder={t('engine.engineForm.name.placeholder')} />
                        </Form.Item>

                        <Form.Item
                            name="engineId"
                            label={t('engine.engineForm.engineId.title')}
                            rules={[
                                {
                                    required: true,
                                    message: t('engine.engineForm.engineId.required')
                                }
                            ]}
                        >
                            <Input
                                placeholder={t('engine.engineForm.engineId.placeholder')}
                                disabled={isUpdateForm}
                            />
                        </Form.Item>
                        {location === ENGINE_LOCATION.DEAD_FISH_DETECTION_ENGINE_IN_SITE && (
                            <Form.Item
                                label={t('engine.engineForm.localityNumber.title')}
                                name="externalId"
                                rules={[
                                    {
                                        required: true,
                                        message: t('engine.engineForm.engineId.required')
                                    }
                                ]}
                            >
                                <FDSelection
                                    listSelectItem={engineSiteSelection}
                                    placeholder={t('engine.engineForm.localityNumber.placeholder')}
                                    disabled={isUpdateForm}
                                />
                            </Form.Item>
                        )}

                        {(location === ENGINE_LOCATION.STRESS_DETECTION_ENGINE_IN_SERVICE_BOAT ||
                            location === ENGINE_LOCATION.FLOW_DETECTION_ENGINE_IN_SERVICE_BOAT ||
                            location === ENGINE_LOCATION.RTDE_ENGINE_IN_SERVICE_BOAT) && (
                            <Form.Item
                                label={t('engine.engineForm.externalServiceBoatId.title')}
                                name="externalId"
                                rules={[
                                    {
                                        required: true,
                                        message: t('engine.engineForm.engineId.required')
                                    }
                                ]}
                            >
                                <FDSelection
                                    listSelectItem={engineServiceBoatSelection}
                                    placeholder={t(
                                        'engine.engineForm.externalServiceBoatId.placeholder'
                                    )}
                                    disabled={isUpdateForm}
                                />
                            </Form.Item>
                        )}

                        {location ===
                            ENGINE_LOCATION.DEAD_FISH_DETECTION_ENGINE_IN_FISHERY_BOAT && (
                            <Form.Item
                                label={t('engine.engineForm.externalFisheryBoatId.title')}
                                name="externalId"
                                rules={[
                                    {
                                        required: true,
                                        message: t('engine.engineForm.engineId.required')
                                    }
                                ]}
                            >
                                <FDSelection
                                    listSelectItem={engineFisheryBoatSelection}
                                    placeholder={t(
                                        'engine.engineForm.externalFisheryBoatId.placeholder'
                                    )}
                                    disabled={isUpdateForm}
                                />
                            </Form.Item>
                        )}

                        <Form.Item
                            name="description"
                            label={t('engine.engineForm.description.title')}
                        >
                            <Input placeholder={t('engine.engineForm.description.placeholder')} />
                        </Form.Item>

                        <Form.Item
                            label={t('engine.engineForm.configurationJson.title')}
                            name="configurationJson"
                            rules={[
                                {
                                    required: true,
                                    message: t('engine.engineForm.configurationJson.required')
                                }
                            ]}
                        >
                            <TextArea
                                rows={15}
                                placeholder={t('engine.engineForm.configurationJson.placeholder')}
                            />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </div>
    );
};

export default EngineForm;
