import { Card, Col, Row, Spin } from 'antd';
import { Title } from 'common/constants';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './FDItemList.scss';

const FDItemList = ({ loading = false, title = '', itemList = [] }) => {
    const { t } = useTranslation();

    return (
        <div>
            <Title level={4}>{title}</Title>
            <Spin spinning={loading}>
                <Card bordered className="item-list">
                    <Row gutter={[8, 8]}>
                        {itemList.map((item) => {
                            return (
                                <Col
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={6}
                                    xl={4}
                                    xxl={4}
                                    className="item"
                                    key={item.id}
                                >
                                    <Link key={item?.id}>
                                        <Card
                                            className={`item flex justify-center items-center font-medium text-base rounded h-28 text-white relative 
                                        ${
                                            item.hasData && !item.hasViewed
                                                ? 'hover:bg-slate-700 cursor-pointer bg-slate-600'
                                                : !item.hasData
                                                ? 'card-disable bg-slate-300'
                                                : 'hover:bg-stone-500 cursor-pointer bg-stone-400'
                                        }
                                        ${item.isCurrentItem && 'border-t-8 border-t-sky-600'}
                                    `}
                                        >
                                            <div>
                                                {item.name}
                                                {!item.hasData && (
                                                    <p className="btn-contact cursor-pointer">
                                                        {t('general.contactUs')}
                                                    </p>
                                                )}
                                            </div>
                                        </Card>
                                    </Link>
                                </Col>
                            );
                        })}
                    </Row>
                </Card>
            </Spin>
        </div>
    );
};

FDItemList.propTypes = {
    loading: PropTypes.bool,
    title: PropTypes.string,
    itemList: PropTypes.array
};

export default FDItemList;
