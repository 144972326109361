import { Button, Col, Row } from 'antd';
import { FDMultipleSelection, FDRangeDate } from 'common/components';
import { BUTTON_BLUE_CLASS, COMPANY_TYPE } from 'common/constants';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCompaniesSelection,
    getFLSProcesses,
    getFTProcesses,
    getPensSelection,
    getServiceBoatsSelection,
    getSitesSelection,
    updateFilters
} from 'redux/thunks';

const FilterBar = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const initialFilters = useSelector((s) => s.filter.data);

    const allCompanies = useSelector((s) => s.companySelection.data);
    const allSites = useSelector((s) => s.siteSelection.data);
    const allPens = useSelector((s) => s.penSelection.data);
    const allServiceBoats = useSelector((s) => s.serviceBoatSelection.data);

    const [farmerCompanySelection, setFarmerCompanySelection] = useState([]);
    const [sitesSelection, setSitesSelection] = useState([]);
    const [pensSelection, setPensSelection] = useState([]);
    const [serviceBoatCompanySelection, setServiceBoatCompanySelection] = useState([]);
    const [serviceBoatSelection, setServiceBoatSelection] = useState([]);

    const [filters, setFilters] = useState({
        farmerOrganizationIds: initialFilters.farmerOrganizationIds,
        farmerSites: initialFilters.farmerSites,
        farmerPens: initialFilters.farmerPens,
        serviceBoatOrganizationIds: initialFilters.serviceBoatOrganizationIds,
        externalBoatIds: initialFilters.externalBoatIds,
        fromDate: initialFilters.fromDate,
        toDate: initialFilters.toDate
    });

    useEffect(() => {
        dispatch(
            getCompaniesSelection({
                companyTypes: [COMPANY_TYPE.FARMER, COMPANY_TYPE.SERVICE_BOAT]
            })
        );
        dispatch(getSitesSelection());
        dispatch(getPensSelection());
        dispatch(getServiceBoatsSelection());

        dispatch(getFTProcesses(filters));
        dispatch(getFLSProcesses(filters));
    }, []);

    useEffect(() => {
        setFarmerCompanySelection(
            allCompanies.filter((company) => company.companyTypes.includes(COMPANY_TYPE.FARMER))
        );
        setServiceBoatCompanySelection(
            allCompanies.filter((company) =>
                company.companyTypes.includes(COMPANY_TYPE.SERVICE_BOAT)
            )
        );
    }, [allCompanies]);

    useEffect(() => {
        setSitesSelection(allSites);
    }, [allSites]);

    useEffect(() => {
        setPensSelection(allPens);
    }, [allPens]);

    useEffect(() => {
        setServiceBoatSelection(allServiceBoats);
    }, [allServiceBoats]);

    const handleFarmerCompaniesChange = (farmerOrganizationIds) => {
        let selectedSites = filters.farmerSites;
        let newSitesSelections = allSites;

        let selectedPens = filters.farmerPens;
        let newPensSelections = allPens;

        if (farmerOrganizationIds.length) {
            newSitesSelections = newSitesSelections.filter((site) =>
                farmerOrganizationIds.includes(site.organizationId)
            );

            selectedSites = selectedSites.filter((site) =>
                newSitesSelections.some((siteSelection) => siteSelection.id === site.id)
            );

            newPensSelections = newPensSelections.filter((pen) =>
                farmerOrganizationIds.includes(pen.organizationId)
            );

            selectedPens = selectedPens.filter((pen) =>
                newPensSelections.some((penSelection) => penSelection.id === pen.id)
            );
        }

        if (selectedSites.length) {
            newPensSelections = newPensSelections.filter((pen) =>
                selectedSites.some((site) => site.id === pen.localityNumber)
            );
        }

        setSitesSelection(newSitesSelections);
        setPensSelection(newPensSelections);
        setFilters({
            ...filters,
            farmerOrganizationIds,
            farmerSites: selectedSites,
            farmerPens: selectedPens
        });
    };

    const handleSitesChange = (siteIds, farmerSites) => {
        let selectedPens = filters.farmerPens;
        let newPensSelections = allPens;

        if (siteIds.length) {
            newPensSelections = newPensSelections.filter((pen) =>
                siteIds.includes(pen.localityNumber)
            );

            selectedPens = selectedPens.filter((pen) =>
                newPensSelections.some((penSelection) => penSelection.id === pen.id)
            );
        }

        if (filters.farmerOrganizationIds.length) {
            newPensSelections = newPensSelections.filter((pen) =>
                filters.farmerOrganizationIds.includes(pen.organizationId)
            );
        }

        setPensSelection(newPensSelections);
        setFilters({
            ...filters,
            farmerSites,
            farmerPens: selectedPens
        });
    };

    const handlePensChange = (penIds, farmerPens) => {
        setFilters({
            ...filters,
            farmerPens
        });
    };

    const handleServiceBoatCompaniesChange = (serviceBoatOrganizationIds) => {
        let selectedExternalBoatIds = filters.externalBoatIds;
        let newServiceBoatsSelections = allServiceBoats;

        if (serviceBoatOrganizationIds.length) {
            newServiceBoatsSelections = newServiceBoatsSelections.filter((sb) =>
                serviceBoatOrganizationIds.includes(sb.organizationId)
            );

            selectedExternalBoatIds = selectedExternalBoatIds.filter((externalBoatId) =>
                newServiceBoatsSelections.some((sbSelection) => sbSelection.id === externalBoatId)
            );
        }

        setServiceBoatSelection(newServiceBoatsSelections);
        setFilters({
            ...filters,
            serviceBoatOrganizationIds,
            externalBoatIds: selectedExternalBoatIds
        });
    };

    const handleServiceBoatsChange = (externalBoatIds) => {
        setFilters({
            ...filters,
            externalBoatIds
        });
    };

    const handleRangeDateChange = (fromDate, toDate) => {
        setFilters({
            ...filters,
            fromDate: fromDate,
            toDate: toDate
        });
    };

    const handleFilterSubmit = () => {
        const apiFilters = {
            ...filters,
            farmerSites: filters.farmerSites.map((site) => ({
                organizationId: site.organizationId,
                localityNumber: site.id
            })),
            farmerPens: filters.farmerPens.map((pen) => ({
                organizationId: pen.organizationId,
                localityNumber: pen.localityNumber,
                penNumber: pen.penNumber
            }))
        };

        dispatch(getFTProcesses(apiFilters));
        dispatch(getFLSProcesses(apiFilters));
        dispatch(updateFilters({ ...initialFilters, ...apiFilters }));
    };

    return (
        <Row className="md:text-base filter">
            <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={5}>
                <div className="font-semibold">{t('process.filterBar.farmerCompany.title')}</div>
                <FDMultipleSelection
                    placeholder={t('general.filterBar.companiesPlaceholder')}
                    listSelectItem={farmerCompanySelection}
                    onChange={handleFarmerCompaniesChange}
                    value={filters.farmerOrganizationIds}
                />
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={5}>
                <div className="font-semibold">{t('general.filterBar.site')}</div>
                <FDMultipleSelection
                    placeholder={t('general.filterBar.sitesPlaceholder')}
                    listSelectItem={sitesSelection}
                    onChange={handleSitesChange}
                    value={filters.farmerSites.map((site) => site.id)}
                />
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={5}>
                <div className="font-semibold">{t('general.filterBar.pen')}</div>
                <FDMultipleSelection
                    placeholder={t('general.filterBar.pensPlaceholder')}
                    listSelectItem={pensSelection}
                    onChange={handlePensChange}
                    value={filters.farmerPens.map((pen) => pen.id)}
                />
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={5}>
                <div className="font-semibold">
                    {t('process.filterBar.serviceBoatCompany.title')}
                </div>
                <FDMultipleSelection
                    placeholder={t('general.filterBar.companiesPlaceholder')}
                    listSelectItem={serviceBoatCompanySelection}
                    onChange={handleServiceBoatCompaniesChange}
                    value={filters.serviceBoatOrganizationIds}
                />
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={5}>
                <div className="font-semibold">{t('general.filterBar.boat')}</div>
                <FDMultipleSelection
                    placeholder={t('general.filterBar.boatsPlaceholder')}
                    listSelectItem={serviceBoatSelection}
                    onChange={handleServiceBoatsChange}
                    value={filters.externalBoatIds}
                />
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={5}>
                <div className="font-semibold">{t('general.filterBar.rangeDate')}</div>
                <FDRangeDate
                    placeholder={[
                        t('general.filterBar.startDatePlaceholder'),
                        t('general.filterBar.endDatePlaceholder')
                    ]}
                    onChange={handleRangeDateChange}
                    value={[filters.fromDate, filters.toDate]}
                />
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={3} className="flex items-end">
                <Button className={`${BUTTON_BLUE_CLASS} !mb-0`} onClick={handleFilterSubmit}>
                    {t('general.filterBar.apply')}
                </Button>
            </Col>
        </Row>
    );
};

export default FilterBar;
