import { DATE_FORMAT } from 'common/constants';
import moment from 'moment';

export const formatDateTime = (value, format = DATE_FORMAT) => {
    if (!value) return;
    return moment(value).format(format);
};

export const formatTime = (timer) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes} : ${getSeconds}`;
};

export const getPreviousDate = (n) => {
    let today = new Date();
    return new Date(new Date().setDate(today.getDate() - n));
};

export const getTimeAgo = (timestamp) => {
    const currentDate = new Date();
    const seconds = Math.floor((currentDate - timestamp) / 1000);

    if (seconds < 60) {
        return 'just now';
    }

    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
        return `${minutes} minutes ago`;
    }

    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
        return `${hours} hours ago`;
    }

    const days = Math.floor(hours / 24);
    if (days < 30) {
        return `${days} days ago`;
    }

    const months = Math.floor(days / 30);
    if (months < 12) {
        return `${months} months ago`;
    }

    const years = Math.floor(months / 12);
    return `${years} years ago`;
};
