import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FILE_TYPE, LIMITED_FILE_SIZE } from 'common/constants';

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const beforeUpload = async (file) => {
    const isValidType = FILE_TYPE.includes(file.type);
    if (!isValidType) {
        await message.error('You can only upload ' + FILE_TYPE.toString() + ' file!');
        return;
    }

    const isValidSize = file.size < LIMITED_FILE_SIZE;
    if (!isValidSize) {
        await message.error('Image must smaller than 2MB!');
        return;
    }

    return isValidType && isValidSize;
};

const FDUploadImage = ({ uploadedHandler, url = '', isAvatar = false }) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(url);

    useEffect(() => {
        setImageUrl(url);
    }, [url]);

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }

        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url);
                if (uploadedHandler) {
                    uploadedHandler(info.file.response.url);
                }
            });
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8
                }}
            >
                Upload
            </div>
        </div>
    );

    const actionURL = `${
        process.env.REACT_APP_MEDIA_API_URL
    }api/media?folder=images&fileName=${uuidv4()}`;

    const uploadContent = () => {
        return (
            <Upload
                name="file"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={actionURL}
                beforeUpload={beforeUpload}
                onChange={handleChange}
            >
                {imageUrl && !loading ? (
                    <img
                        src={imageUrl}
                        alt="avatar"
                        style={{
                            width: '100%'
                        }}
                    />
                ) : (
                    uploadButton
                )}
            </Upload>
        );
    };

    return isAvatar ? <ImgCrop rotationSlider>{uploadContent()}</ImgCrop> : uploadContent();
};

export default FDUploadImage;
