import { Button, Form, Input, Modal, Spin } from 'antd';
import { FDMultipleSelection } from 'common/components';
import { SUCCESSFULLY_CREATION_MESSAGE } from 'common/constants';
import { TENANT_TYPES } from 'common/constants/tenant-type';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createTenant, getHeaderTenants, getTenants } from 'redux/thunks';

const TenantForm = ({ open = false, onClose = () => {} }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const onBoardingSites = useSelector((s) => s.onBoardingSite.data);
    const onBoardingServiceBoats = useSelector((s) => s.onBoardingServiceBoat.data);
    const servicesSelection = useSelector((s) => s.serviceSelection.data);
    const originalSitesSelection = useSelector((s) => s.originalSiteSelection.data);
    const upsertLoading = useSelector((s) => s.tenant.loading);
    const tenantTypesSelection = useSelector((s) => s.tenantTypeSelection.data);

    const tenantTypeNames = Form.useWatch('tenantTypeNames', form);

    const handleTenantCreate = async (req) => {
        if (req.tenantTypeNames?.includes(TENANT_TYPES.FARMER)) {
            req.onBoardingSites = onBoardingSites.map((onBoardingSite) => ({
                siteName: onBoardingSite,
                localityNumber: req[onBoardingSite]
            }));
        }

        if (req.tenantTypeNames?.includes(TENANT_TYPES.SERVICE_BOAT)) {
            req.onBoardingServiceBoats = onBoardingServiceBoats.map((onBoardingServiceBoat) => ({
                name: onBoardingServiceBoat,
                externalBoatId: req[onBoardingServiceBoat]
            }));
        }

        try {
            await dispatch(createTenant(req)).unwrap();

            dispatch(getTenants());
            dispatch(getHeaderTenants());
            alertSuccessMessage(t(SUCCESSFULLY_CREATION_MESSAGE));
            form.resetFields();
            onClose();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const handleModalCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <div className="modal-create-tenant">
            <Modal
                title={t('tenant.tenantForm.createTenant')}
                open={open}
                onCancel={handleModalCancel}
                width={650}
                footer={
                    <>
                        <Button type="default" onClick={handleModalCancel} className="m-1">
                            {t('general.form.cancel')}
                        </Button>
                        <Button
                            onClick={form.submit}
                            className="m-1 bg-sky-700 hover:bg-sky-800 text-white hover:text-white"
                        >
                            {t('general.form.create')}
                        </Button>
                    </>
                }
            >
                <Spin spinning={upsertLoading}>
                    <Form
                        name="basic"
                        labelCol={{ span: 10 }}
                        wrapperCol={{ span: 13 }}
                        onFinish={handleTenantCreate}
                        autoComplete="off"
                        form={form}
                    >
                        <Form.Item
                            name="tenantName"
                            label={t('tenant.tenantForm.tenantName.title')}
                            rules={[
                                {
                                    required: true,
                                    message: t('tenant.tenantForm.tenantName.required')
                                }
                            ]}
                        >
                            <Input placeholder={t('tenant.tenantForm.tenantName.placeholder')} />
                        </Form.Item>

                        <Form.Item
                            name="azureTenantId"
                            label={t('tenant.tenantForm.azureTenantId.title')}
                            rules={[
                                {
                                    required: true,
                                    message: t('tenant.tenantForm.azureTenantId.required')
                                }
                            ]}
                        >
                            <Input placeholder={t('tenant.tenantForm.azureTenantId.placeholder')} />
                        </Form.Item>

                        <Form.Item
                            name="companyName"
                            label={t('tenant.tenantForm.companyName.title')}
                            rules={[
                                {
                                    required: true,
                                    message: t('tenant.tenantForm.companyName.required')
                                }
                            ]}
                        >
                            <Input placeholder={t('tenant.tenantForm.companyName.placeholder')} />
                        </Form.Item>

                        <Form.Item
                            name="organizationId"
                            label={t('tenant.tenantForm.organizationId.title')}
                            rules={[
                                {
                                    required: true,
                                    message: t('tenant.tenantForm.organizationId.required')
                                }
                            ]}
                        >
                            <Input
                                placeholder={t('tenant.tenantForm.organizationId.placeholder')}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t('tenant.tenantForm.services.title')}
                            name="externalServiceIds"
                        >
                            <FDMultipleSelection
                                placeholder={t('tenant.tenantForm.services.placeholder')}
                                listSelectItem={servicesSelection}
                                dataTestId="service-option"
                            />
                        </Form.Item>

                        <Form.Item
                            label={t('tenant.tenantForm.types.title')}
                            name="tenantTypeNames"
                            className="types"
                            rules={[
                                {
                                    required: true,
                                    message: t('tenant.tenantForm.types.required')
                                }
                            ]}
                        >
                            <FDMultipleSelection
                                placeholder={t('tenant.tenantForm.types.placeholder')}
                                listSelectItem={tenantTypesSelection}
                                dataTestId="types-option"
                            />
                        </Form.Item>

                        {tenantTypeNames?.includes(TENANT_TYPES.FARMER) && (
                            <>
                                {onBoardingSites.map((site) => (
                                    <Form.Item
                                        name={site}
                                        label={
                                            t('tenant.tenantForm.localityNumber.title') +
                                            ` (${site})`
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'tenant.tenantForm.localityNumber.required'
                                                )
                                            },
                                            {
                                                min: 6,
                                                message: t(
                                                    'tenant.tenantForm.localityNumber.minLength'
                                                )
                                            }
                                        ]}
                                        key={site}
                                    >
                                        <Input
                                            placeholder={t(
                                                'tenant.tenantForm.localityNumber.placeholder'
                                            )}
                                            type="number"
                                        />
                                    </Form.Item>
                                ))}

                                <Form.Item
                                    label={t('tenant.tenantForm.originalSite.title')}
                                    name="localityNumbers"
                                    className="localityNumbers"
                                >
                                    <FDMultipleSelection
                                        placeholder={t(
                                            'tenant.tenantForm.originalSite.placeholder'
                                        )}
                                        listSelectItem={originalSitesSelection}
                                        dataTestId="original-site-option"
                                    />
                                </Form.Item>
                            </>
                        )}

                        {tenantTypeNames?.includes(TENANT_TYPES.SERVICE_BOAT) &&
                            onBoardingServiceBoats.map((serviceBoat) => (
                                <>
                                    <Form.Item
                                        name={serviceBoat}
                                        label={
                                            t('tenant.tenantForm.externalBoatId.title') +
                                            ` (${serviceBoat})`
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'tenant.tenantForm.externalBoatId.required'
                                                )
                                            }
                                        ]}
                                        key={serviceBoat}
                                    >
                                        <Input
                                            placeholder={t(
                                                'tenant.tenantForm.externalBoatId.placeholder'
                                            )}
                                        />
                                    </Form.Item>
                                </>
                            ))}
                    </Form>
                </Spin>
            </Modal>
        </div>
    );
};

TenantForm.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};

export default TenantForm;
