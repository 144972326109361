import { Button, Form, Input, Modal, Spin, InputNumber } from 'antd';
import { FDSelection } from 'common/components';
import {
    COMPANY_TYPE,
    SUCCESSFULLY_CREATION_MESSAGE,
    SUCCESSFULLY_EDIT_MESSAGE,
    TextArea
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    createSite,
    getCompaniesSelection,
    getOriginalSitesSelection,
    getSites,
    getSitesContainingPens,
    updateSite
} from 'redux/thunks';

function SiteForm({ open = false, site, onClose = () => {}, onReload = () => {} }) {
    const [form] = Form.useForm();

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const upsertLoading = useSelector((s) => s.site.loading || false);
    const { data: originalSitesSelection, originalSitesLoading = false } = useSelector(
        (s) => s.originalSiteSelection || {}
    );
    const companiesSelection = useSelector((s) => s.companySelection.data || []);

    const isUpdateForm = !!site;

    useEffect(() => {
        dispatch(getCompaniesSelection({ companyTypes: [COMPANY_TYPE.FARMER] }));
    }, []);

    useEffect(() => {
        if (open) {
            dispatch(getOriginalSitesSelection());
        }
    }, [open]);

    useEffect(() => {
        if (isUpdateForm) {
            form.setFieldsValue(site);
        } else {
            form.resetFields();
        }
    }, [site]);

    const createOrUpdateSite = (req) => {
        if (isUpdateForm) {
            handleUpdateSite(req);
        } else {
            handleCreateSite(req);
        }
    };

    const handleCreateSite = async (req) => {
        try {
            await dispatch(createSite(req)).unwrap();

            dispatch(getSites());
            dispatch(getSitesContainingPens());
            alertSuccessMessage(t(SUCCESSFULLY_CREATION_MESSAGE));
            form.resetFields();
            onClose();
            // after create new site, tab should be active tab
            // when the tab change it already dispatch new site list
            onReload();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const handleUpdateSite = async (req) => {
        try {
            await dispatch(updateSite({ id: site.id, item: req })).unwrap();

            dispatch(getSites());
            alertSuccessMessage(t(SUCCESSFULLY_EDIT_MESSAGE));
            onClose();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            title={isUpdateForm ? t('site.siteForm.editSite') : t('site.siteForm.createSite')}
            open={open}
            onCancel={handleCancel}
            footer={
                <>
                    <Button type="default" onClick={handleCancel} className="m-1">
                        {t('general.form.cancel')}
                    </Button>
                    <Button
                        onClick={form.submit}
                        className="m-1 bg-sky-700 hover:bg-sky-800 text-white hover:text-white"
                    >
                        {isUpdateForm ? t('general.form.saveChange') : t('general.form.create')}
                    </Button>
                </>
            }
        >
            <Spin spinning={upsertLoading || originalSitesLoading}>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={createOrUpdateSite}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item label="id" name="id" hidden={true} required={false}>
                        <Input placeholder="id..." />
                    </Form.Item>

                    <Form.Item
                        label={t('site.siteForm.name.title')}
                        name="localityNumber"
                        rules={[
                            {
                                required: !isUpdateForm,
                                message: t('site.siteForm.name.required')
                            }
                        ]}
                        hidden={isUpdateForm}
                    >
                        <FDSelection
                            placeholder={t('site.siteForm.name.placeholder')}
                            listSelectItem={originalSitesSelection}
                            disabled={isUpdateForm}
                            dataTestId="site-option"
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('site.siteForm.name.title')}
                        name="siteName"
                        hidden={!isUpdateForm}
                        required={false}
                    >
                        <Input placeholder={t('site.siteForm.name.title')} disabled />
                    </Form.Item>

                    <Form.Item
                        name="organizationId"
                        label={t('site.siteForm.company.title')}
                        rules={[
                            {
                                required: true,
                                message: t('site.siteForm.company.required')
                            }
                        ]}
                    >
                        <FDSelection
                            listSelectItem={companiesSelection}
                            placeholder={t('site.siteForm.company.placeholder')}
                            dataTestId="company-option"
                            disabled={site?.hasData}
                        />
                    </Form.Item>

                    <Form.Item label={t('site.siteForm.description.title')} name="description">
                        <TextArea placeholder={t('site.siteForm.description.placeholder')} />
                    </Form.Item>

                    <Form.Item label={t('site.siteForm.latitude.title')} name="latitude">
                        <Input placeholder={t('site.siteForm.latitude.placeholder')} />
                    </Form.Item>

                    <Form.Item label={t('site.siteForm.longitude.title')} name="longitude">
                        <Input placeholder={t('site.siteForm.longitude.placeholder')} />
                    </Form.Item>

                    <Form.Item
                        label={t('site.siteForm.locationNumber.title')}
                        name="localityNumber"
                        hidden={!isUpdateForm}
                        required={false}
                    >
                        <Input
                            placeholder={t('site.siteForm.locationNumber.placeholder')}
                            disabled
                        />
                    </Form.Item>

                    <Form.Item label={t('site.siteForm.capacity.title')} name="capacity">
                        <Input placeholder={t('site.siteForm.capacity.placeholder')} />
                    </Form.Item>

                    <Form.Item label={t('site.siteForm.municipality.title')} name="municipality">
                        <Input placeholder={t('site.siteForm.municipality.placeholder')} />
                    </Form.Item>

                    <Form.Item
                        label={t('site.siteForm.address.title')}
                        name="address"
                        className="address"
                    >
                        <Input placeholder={t('site.siteForm.address.placeholder')} />
                    </Form.Item>

                    <Form.Item
                        label={t('site.siteForm.numbersOfPen.title')}
                        name="numbersOfPen"
                        hidden={isUpdateForm}
                    >
                        <InputNumber
                            min={0}
                            className="w-full"
                            placeholder={t('site.siteForm.numbersOfPen.placeholder')}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
}

SiteForm.propTypes = {
    open: PropTypes.bool,
    site: PropTypes.object,
    onClose: PropTypes.func,
    onCreate: PropTypes.func
};

export default SiteForm;
