import { Button, Form, Input, Modal, Spin } from 'antd';
import { FDSelection } from 'common/components';
import {
    COMPANY_TYPE,
    SUCCESSFULLY_CREATION_MESSAGE,
    SUCCESSFULLY_EDIT_MESSAGE,
    TextArea
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    createPen,
    getCompaniesSelection,
    getPens,
    getSitesContainingPens,
    getSitesSelection,
    updatePen
} from 'redux/thunks';

const PenForm = ({ open = false, pen, onClose = () => {}, onReload = () => {} }) => {
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const upsertLoading = useSelector((s) => s.pen.loading || false);
    const companiesSelection = useSelector((s) => s.companySelection.data || []);
    const sitesSelection = useSelector((s) => s.siteSelection.data || []);

    const organizationId = Form.useWatch('organizationId', form);

    const isUpdateForm = !!pen;

    useEffect(() => {
        dispatch(getCompaniesSelection({ companyTypes: [COMPANY_TYPE.FARMER] }));
        dispatch(getSitesSelection());
    }, []);

    useEffect(() => {
        if (isUpdateForm) {
            form.setFieldsValue(pen);
        } else {
            form.resetFields();
        }
    }, [pen]);

    const handleCompanyChange = () => {
        form.setFieldsValue({
            ['localityNumber']: null
        });
    };

    const handleSiteChange = (value, object) => {
        if (!organizationId) {
            form.setFieldsValue({
                ['organizationId']: object.organizationId
            });
        }
    };

    const createOrUpdatePen = async (req) => {
        if (isUpdateForm) {
            handleUpdatePen(req);
        } else {
            handleCreatePen(req);
        }
    };

    const handleCreatePen = async (req) => {
        try {
            await dispatch(createPen(req)).unwrap();

            dispatch(getPens({ isDeleted: false }));
            dispatch(getSitesContainingPens());
            alertSuccessMessage(t(SUCCESSFULLY_CREATION_MESSAGE));
            form.resetFields();
            onClose();
            onReload();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const handleUpdatePen = async (req) => {
        try {
            await dispatch(updatePen({ id: pen.id, item: req })).unwrap();

            dispatch(getPens({ isDeleted: false }));
            alertSuccessMessage(t(SUCCESSFULLY_EDIT_MESSAGE));
            onClose();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            title={isUpdateForm ? t('pen.penForm.editPen') : t('pen.penForm.createPen')}
            open={open}
            onCancel={handleCancel}
            onOk={onClose}
            width={580}
            footer={
                <>
                    <Button type="default" onClick={handleCancel} className="m-1">
                        {t('general.form.cancel')}
                    </Button>
                    <Button
                        onClick={form.submit}
                        className="m-1 bg-sky-700 hover:bg-sky-800 text-white hover:text-white"
                    >
                        {isUpdateForm ? t('general.form.saveChange') : t('general.form.create')}
                    </Button>
                </>
            }
        >
            <Spin spinning={upsertLoading}>
                <Form
                    name="basic"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={createOrUpdatePen}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item label="id" name="id" hidden={true} required={false}>
                        <Input placeholder="id..." />
                    </Form.Item>

                    <Form.Item
                        label={t('pen.penForm.penNumber.title')}
                        name="penNumber"
                        rules={[
                            {
                                required: true,
                                message: t('pen.penForm.penNumber.required')
                            }
                        ]}
                    >
                        <Input
                            placeholder={t('pen.penForm.penNumber.placeholder')}
                            disabled={isUpdateForm}
                        />
                    </Form.Item>

                    <Form.Item label={t('pen.penForm.description.title')} name="description">
                        <TextArea placeholder={t('pen.penForm.description.placeholder')} />
                    </Form.Item>

                    <Form.Item
                        label={t('pen.penForm.company.title')}
                        name="organizationId"
                        rules={[
                            {
                                required: true,
                                message: t('pen.penForm.company.required')
                            }
                        ]}
                    >
                        <FDSelection
                            placeholder={t('pen.penForm.company.placeholder')}
                            listSelectItem={companiesSelection}
                            onChange={handleCompanyChange}
                            disabled={isUpdateForm}
                            dataTestId="company-option"
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('pen.penForm.localityNumber.title')}
                        name="localityNumber"
                        rules={[
                            {
                                required: true,
                                message: t('pen.penForm.localityNumber.required')
                            }
                        ]}
                    >
                        <FDSelection
                            placeholder={t('pen.penForm.localityNumber.placeholder')}
                            listSelectItem={sitesSelection.filter(
                                (site) => !organizationId || site.organizationId === organizationId
                            )}
                            onChange={handleSiteChange}
                            disabled={isUpdateForm}
                            dataTestId="organization-option"
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

PenForm.propTypes = {
    open: PropTypes.bool,
    pen: PropTypes.object,
    onClose: PropTypes.func,
    onReload: PropTypes.func
};

export default PenForm;
