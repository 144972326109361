import { Button, Form, Input, Modal, Spin } from 'antd';
import { FDSelection } from 'common/components';
import {
    COMPANY_TYPE,
    SUCCESSFULLY_CREATION_MESSAGE,
    SUCCESSFULLY_EDIT_MESSAGE,
    TextArea
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    createFisheryBoat,
    getCompaniesSelection,
    getFisheryBoats,
    updateFisheryBoat
} from 'redux/thunks';

const FisheryBoatForm = ({ open = false, boat, onClose = () => {}, onReload = () => {} }) => {
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const upsertLoading = useSelector((s) => s.fisheryBoat.loading || false);
    const companiesSelection = useSelector((s) => s.companySelection.data || []);

    const isUpdateForm = !!boat;

    useEffect(() => {
        dispatch(getCompaniesSelection({ companyTypes: [COMPANY_TYPE.FISHERY] }));
    }, []);

    useEffect(() => {
        if (isUpdateForm) {
            form.setFieldsValue(boat);
        } else {
            form.resetFields();
        }
    }, [boat]);

    const createOrUpdateBoat = async (req) => {
        if (isUpdateForm) {
            handleUpdateBoat(req);
        } else {
            handleCreateBoat(req);
        }
    };

    const handleCreateBoat = async (req) => {
        try {
            await dispatch(createFisheryBoat(req)).unwrap();

            dispatch(getFisheryBoats({ isDeleted: false }));
            alertSuccessMessage(t(SUCCESSFULLY_CREATION_MESSAGE));
            form.resetFields();
            onClose();
            onReload();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const handleUpdateBoat = async (req) => {
        try {
            await dispatch(updateFisheryBoat({ id: boat.id, item: req })).unwrap();

            dispatch(getFisheryBoats({ isDeleted: false }));
            alertSuccessMessage(t(SUCCESSFULLY_EDIT_MESSAGE));
            onClose();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            title={
                isUpdateForm
                    ? t('fisheryBoat.fisheryBoatForm.editFisheryBoat')
                    : t('fisheryBoat.fisheryBoatForm.createFisheryBoat')
            }
            open={open}
            onCancel={handleCancel}
            onOk={onClose}
            width={580}
            footer={
                <>
                    <Button type="default" onClick={handleCancel} className="m-1">
                        {t('general.form.cancel')}
                    </Button>
                    <Button
                        onClick={form.submit}
                        className="m-1 bg-sky-700 hover:bg-sky-800 text-white hover:text-white"
                    >
                        {isUpdateForm ? t('general.form.saveChange') : t('general.form.create')}
                    </Button>
                </>
            }
        >
            <Spin spinning={upsertLoading}>
                <Form
                    name="basic"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={createOrUpdateBoat}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item label="id" name="id" hidden={true} required={false}>
                        <Input placeholder="id..." />
                    </Form.Item>

                    <Form.Item
                        name="name"
                        label={t('fisheryBoat.fisheryBoatForm.name.title')}
                        rules={[
                            {
                                required: true,
                                message: t('fisheryBoat.fisheryBoatForm.name.required')
                            }
                        ]}
                    >
                        <Input placeholder={t('fisheryBoat.fisheryBoatForm.name.placeholder')} />
                    </Form.Item>

                    <Form.Item
                        name="description"
                        label={t('fisheryBoat.fisheryBoatForm.description.title')}
                    >
                        <TextArea
                            placeholder={t('fisheryBoat.fisheryBoatForm.description.placeholder')}
                        />
                    </Form.Item>

                    <Form.Item
                        name="externalBoatId"
                        label={t('fisheryBoat.fisheryBoatForm.externalBoatId.title')}
                        rules={[
                            {
                                required: true,
                                message: t('fisheryBoat.fisheryBoatForm.externalBoatId.required')
                            }
                        ]}
                    >
                        <Input
                            disabled={isUpdateForm}
                            placeholder={t(
                                'fisheryBoat.fisheryBoatForm.externalBoatId.placeholder'
                            )}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('fisheryBoat.fisheryBoatForm.fisheryBoatOwner.title')}
                        name="ownerOrganizationId"
                        rules={[
                            {
                                required: true,
                                message: t('fisheryBoat.fisheryBoatForm.fisheryBoatOwner.required')
                            }
                        ]}
                    >
                        <FDSelection
                            placeholder={t(
                                'fisheryBoat.fisheryBoatForm.fisheryBoatOwner.placeholder'
                            )}
                            listSelectItem={companiesSelection}
                            disabled={boat?.hasData}
                            dataTestId="boat-option"
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

FisheryBoatForm.propTypes = {
    open: PropTypes.bool,
    boat: PropTypes.object,
    onClose: PropTypes.func,
    onReload: PropTypes.func
};

export default FisheryBoatForm;
