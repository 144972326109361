import {
    DeleteOutlined,
    EditOutlined,
    GlobalOutlined,
    PlusOutlined,
    RestOutlined,
    RollbackOutlined
} from '@ant-design/icons';
import { Button, Col, Row, Space, Spin, Tabs } from 'antd';
import { FDTable } from 'common/components';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_GRAY_CLASS,
    BUTTON_GREEN_CLASS,
    SUCCESSFULLY_DELETION_MESSAGE,
    SUCCESSFULLY_RESTORE_MESSAGE,
    Title
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { deletePen, getPens, getSitesContainingPens, restorePen } from 'redux/thunks';
import Swal from 'sweetalert2';
import PenForm from './PenForm';

const Pen = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { data: pens = [], loading = false } = useSelector((s) => s.pen || {});

    const [isDeleteTab, setIsDeleteTab] = useState(false);
    const [modalState, setModalState] = useState({ open: false, pen: null });

    const columns = [
        {
            dataIndex: 'penNumber',
            title: 'pen.penTable.penNumber',
            sorter: {
                compare: (a, b) => a.penNumber.localeCompare(b.penNumber),
                multiple: 1
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            dataIndex: 'description',
            title: 'pen.penTable.description'
        },
        {
            dataIndex: 'siteName',
            title: 'pen.penTable.siteName'
        },
        {
            dataIndex: 'localityNumber',
            title: 'pen.penTable.localityNumber'
        },
        {
            dataIndex: 'companyName',
            title: 'pen.penTable.company'
        },
        {
            title: 'pen.penTable.action',
            dataIndex: 'Action',
            render: (_, record) => (
                <Space size="middle">
                    {isDeleteTab ? (
                        <Button
                            className={BUTTON_GRAY_CLASS}
                            onClick={() => handleRestorePen(record.id)}
                        >
                            <RollbackOutlined />
                            <span>{t('general.action.restore')}</span>
                        </Button>
                    ) : (
                        <>
                            <Button
                                className={BUTTON_GRAY_CLASS}
                                onClick={() => handleShowModal(record)}
                            >
                                <EditOutlined />
                                <span>{t('general.action.edit')}</span>
                            </Button>
                            <Button
                                className={BUTTON_GREEN_CLASS}
                                onClick={() => handleDeletePen(record.id)}
                            >
                                <DeleteOutlined />
                                <span>{t('general.action.delete')}</span>
                            </Button>
                        </>
                    )}
                </Space>
            )
        }
    ];

    useEffect(() => {
        dispatch(getPens({ isDeleted: isDeleteTab }));
    }, [isDeleteTab]);

    const handleShowModal = (pen) => setModalState({ open: true, pen });

    const handleDeletePen = (id = null) => {
        Swal.fire({
            title: t('general.action.deleteModal.title'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0369a1',
            cancelButtonColor: '#64748b',
            confirmButtonText: t('general.action.deleteModalWithoutRevert.confirmText'),
            cancelButtonText: t('general.action.deleteModal.cancelText')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(deletePen(id)).unwrap();

                    dispatch(getPens({ isDeleted: isDeleteTab }));
                    dispatch(getSitesContainingPens());
                    alertSuccessMessage(t(SUCCESSFULLY_DELETION_MESSAGE));
                } catch (err) {
                    alertErrorMessage(err, t);
                }
            }
        });
    };

    const handleRestorePen = (id = null) => {
        Swal.fire({
            title: t('general.action.restoreModal.title'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0369a1',
            cancelButtonColor: '#64748b',
            confirmButtonText: t('general.action.restoreModal.confirmText'),
            cancelButtonText: t('general.action.deleteModal.cancelText')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(restorePen(id)).unwrap();

                    dispatch(getPens({ isDeleted: isDeleteTab }));
                    dispatch(getSitesContainingPens());
                    alertSuccessMessage(t(SUCCESSFULLY_RESTORE_MESSAGE));
                } catch (err) {
                    alertErrorMessage(err, t);
                }
            }
        });
    };

    return (
        <>
            <Title level={4}>{t('pen.title')}</Title>
            <Row justify="center">
                <Col span={24}>
                    <Button className={BUTTON_BLUE_CLASS} onClick={() => handleShowModal(null)}>
                        <PlusOutlined />
                        <span>{t('pen.penForm.createPen')}</span>
                    </Button>

                    <Spin spinning={loading}>
                        <Tabs
                            activeKey={isDeleteTab}
                            animated={true}
                            onChange={(isDeleted) => setIsDeleteTab(isDeleted)}
                            items={[
                                {
                                    label: (
                                        <span>
                                            <GlobalOutlined />
                                            <span>{t('pen.activePens')}</span>
                                        </span>
                                    ),
                                    key: false,
                                    children: <FDTable headers={columns} data={pens} rowKey="id" />
                                },
                                {
                                    label: (
                                        <span>
                                            <RestOutlined />
                                            <span>{t('pen.deletedPens')}</span>
                                        </span>
                                    ),
                                    key: true,
                                    children: <FDTable headers={columns} data={pens} rowKey="id" />
                                }
                            ]}
                        />
                    </Spin>
                </Col>
            </Row>

            <PenForm
                open={modalState.open}
                pen={modalState.pen}
                onClose={() => setModalState({ open: false, pen: null })}
                onReload={() => setIsDeleteTab(false)}
            />
        </>
    );
};

export default Pen;
