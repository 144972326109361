import { Form, InputNumber, Tag } from 'antd';
import { MORTALITY_CONFIG } from 'common/constants';
import { useTranslation } from 'react-i18next';

const MortalityConfig = () => {
    const { t } = useTranslation();

    return (
        <>
            <Form.Item label={t('company.companyForm.mortalityLevel.title')}>
                <div className="flex flex-wrap items-center xl:justify-between">
                    <Tag color={MORTALITY_CONFIG.ACCEPTABLE.color} className="m-0">
                        {t(MORTALITY_CONFIG.ACCEPTABLE.name)}
                    </Tag>

                    <span className="px-1 font-bold">&lt;&#61;</span>

                    <Form.Item
                        className="m-0"
                        name="firstMortalityLevel"
                        rules={[
                            {
                                required: true,
                                message: t('company.companyForm.mortalityLevel.required')
                            }
                        ]}
                    >
                        <InputNumber
                            placeholder={t('company.companyForm.mortalityLevel.placeholder')}
                            min={0}
                            max={100}
                            style={{ width: 80 }}
                        />
                    </Form.Item>

                    <span className="px-1 font-bold">&lt;</span>

                    <Tag color={MORTALITY_CONFIG.UNWANTED.color} className="m-0">
                        {t(MORTALITY_CONFIG.UNWANTED.name)}
                    </Tag>

                    <span className="px-1 font-bold">&lt;</span>

                    <Form.Item
                        className="m-0"
                        name="secondMortalityLevel"
                        rules={[
                            {
                                required: true,
                                message: t('company.companyForm.mortalityLevel.required')
                            }
                        ]}
                    >
                        <InputNumber
                            placeholder={t('company.companyForm.mortalityLevel.placeholder')}
                            min={0}
                            max={100}
                            style={{ width: 80 }}
                        />
                    </Form.Item>

                    <span className="px-1 font-bold">&lt;&#61;</span>
                    <Tag color={MORTALITY_CONFIG.UNACCEPTABLE.color} className="m-0">
                        {t(MORTALITY_CONFIG.UNACCEPTABLE.name)}
                    </Tag>
                </div>
            </Form.Item>

            <Form.Item
                label={t('company.companyForm.mortalityTrend.title')}
                name="mortalityTrend"
                className="types"
            >
                <div className="flex flex-wrap items-center xl:justify-between">
                    <Tag color={MORTALITY_CONFIG.ACCEPTABLE.color} className="m-0">
                        {t(MORTALITY_CONFIG.ACCEPTABLE.name)}
                    </Tag>

                    <span className="px-1 font-bold">&lt;&#61;</span>

                    <Form.Item
                        className="m-0"
                        name="firstMortalityTrend"
                        rules={[
                            {
                                required: true,
                                message: t('company.companyForm.mortalityTrend.required')
                            }
                        ]}
                    >
                        <InputNumber
                            placeholder={t('company.companyForm.mortalityTrend.placeholder')}
                            min={0}
                            max={100}
                            style={{ width: 80 }}
                        />
                    </Form.Item>

                    <span className="px-1 font-bold">&lt;</span>

                    <Tag color={MORTALITY_CONFIG.UNWANTED.color} className="m-0">
                        {t(MORTALITY_CONFIG.UNWANTED.name)}
                    </Tag>

                    <span className="px-1 font-bold">&lt;</span>

                    <Form.Item
                        className="m-0"
                        name="secondMortalityTrend"
                        rules={[
                            {
                                required: true,
                                message: t('company.companyForm.mortalityTrend.required')
                            }
                        ]}
                    >
                        <InputNumber
                            placeholder={t('company.companyForm.mortalityTrend.placeholder')}
                            min={0}
                            max={100}
                            style={{ width: 80 }}
                        />
                    </Form.Item>

                    <span className="px-1 font-bold">&lt;&#61;</span>

                    <Tag color={MORTALITY_CONFIG.UNACCEPTABLE.color} className="m-0">
                        {t(MORTALITY_CONFIG.UNACCEPTABLE.name)}
                    </Tag>
                </div>
            </Form.Item>
        </>
    );
};

export default MortalityConfig;
