import { FDItemList } from 'components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const SiteList = () => {
    const { t } = useTranslation();
    const { data: sites = [], loading = false } = useSelector((s) => s.sitePen || {});

    const itemList = sites?.map((site) => {
        return {
            id: site.id,
            name: site.siteName,
            link: `../site-detail/${site.id}`,
            hasData: site.hasData
        };
    });

    return (
        <div className="mt-5">
            <FDItemList
                loading={loading}
                title={t('dashboard.fishMortality.siteList.title')}
                itemList={itemList}
            />
        </div>
    );
};

export default SiteList;
