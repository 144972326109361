import { Button, Form, Input, Modal, Spin } from 'antd';
import { FDMultipleSelection, FDSelection, FDUploadImage } from 'common/components';
import {
    SUCCESSFULLY_CREATION_MESSAGE,
    SUCCESSFULLY_EDIT_MESSAGE,
    SERVICE_STATUS
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage, normFile } from 'common/utils';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createService, getServices, updateService, getCompaniesSelection } from 'redux/thunks';

const ServiceForm = ({ open = false, service, onClose = () => {} }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const upsertLoading = useSelector((s) => s.service.loading || false);
    const companiesSelection = useSelector((s) => s.companySelection.data || []);

    const isUpdateForm = !!service;

    useEffect(() => {
        dispatch(getCompaniesSelection({ isFilterByAzureTenantId: false }));
    }, []);

    useEffect(() => {
        if (isUpdateForm) {
            form.setFieldsValue(service);
        } else {
            form.resetFields();
        }
    }, [service]);

    const createOrUpdateService = (req) => {
        if (isUpdateForm) {
            handleUpdateService(req);
        } else {
            handleCreateService(req);
        }
    };

    const handleCreateService = async (req) => {
        try {
            await dispatch(createService(req)).unwrap();

            dispatch(getServices());
            alertSuccessMessage(t(SUCCESSFULLY_CREATION_MESSAGE));
            form.resetFields();
            onClose();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const handleUpdateService = async (req) => {
        try {
            await dispatch(updateService({ id: service.id, item: req })).unwrap();

            dispatch(getServices());
            alertSuccessMessage(t(SUCCESSFULLY_EDIT_MESSAGE));
            onClose();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const handleUploadLogo = (url) => {
        form.setFieldValue('logo', url);
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <div className="modal-create-update-service">
            <Modal
                title={
                    isUpdateForm
                        ? t('service.serviceForm.updateService')
                        : t('service.serviceForm.createService')
                }
                open={open}
                onCancel={handleCancel}
                onOk={onClose}
                width={580}
                footer={
                    <>
                        <Button key="cancel" type="default" onClick={handleCancel} className="m-1">
                            <span>{t('general.form.cancel')}</span>
                        </Button>
                        <Button
                            key="save"
                            onClick={form.submit}
                            className="m-1 bg-sky-700 hover:bg-sky-800 text-white hover:text-white"
                            data-testid="service-ok-button"
                        >
                            <span>
                                {isUpdateForm
                                    ? t('general.form.saveChange')
                                    : t('general.form.create')}
                            </span>
                        </Button>
                    </>
                }
            >
                <Spin spinning={upsertLoading}>
                    <Form
                        name="basic"
                        labelCol={{ span: 7 }}
                        wrapperCol={{ span: 16 }}
                        onFinish={createOrUpdateService}
                        autoComplete="off"
                        form={form}
                    >
                        <Form.Item
                            name="name"
                            label={t('service.serviceForm.name.title')}
                            rules={[
                                {
                                    required: true,
                                    message: t('service.serviceForm.name.required')
                                }
                            ]}
                        >
                            <Input placeholder={t('service.serviceForm.name.placeholder')} />
                        </Form.Item>

                        <Form.Item
                            name="logo"
                            label={t('service.serviceForm.logo.title')}
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            extra="*.png, *.jpg"
                        >
                            <FDUploadImage url={service?.logo} uploadedHandler={handleUploadLogo} />
                        </Form.Item>

                        <Form.Item
                            name="description"
                            label={t('service.serviceForm.description.title')}
                        >
                            <Input placeholder={t('service.serviceForm.description.placeholder')} />
                        </Form.Item>

                        <Form.Item
                            label={t('service.serviceForm.url.title')}
                            name="url"
                            rules={[
                                {
                                    required: true,
                                    message: t('service.serviceForm.url.required')
                                }
                            ]}
                        >
                            <Input placeholder={t('service.serviceForm.url.placeholder')} />
                        </Form.Item>

                        <Form.Item
                            label={t('service.serviceForm.status.title')}
                            name="status"
                            rules={[
                                {
                                    required: true,
                                    message: t('service.serviceForm.status.required')
                                }
                            ]}
                        >
                            <FDSelection
                                listSelectItem={SERVICE_STATUS.map((status) => ({
                                    ...status,
                                    text: t(status.text)
                                }))}
                                placeholder={t('service.serviceForm.status.placeholder')}
                                dataTestId="status-option"
                            />
                        </Form.Item>

                        <Form.Item
                            label={t('service.serviceForm.externalServiceId.title')}
                            name="externalServiceId"
                            rules={[
                                {
                                    required: true,
                                    message: t('service.serviceForm.externalServiceId.required')
                                }
                            ]}
                        >
                            <Input
                                placeholder={t('service.serviceForm.externalServiceId.placeholder')}
                                disabled={isUpdateForm}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t('service.serviceForm.serviceOwner.title')}
                            name="ownerOrganizationId"
                            rules={[
                                {
                                    required: true,
                                    message: t('service.serviceForm.serviceOwner.required')
                                }
                            ]}
                        >
                            <FDSelection
                                listSelectItem={companiesSelection}
                                placeholder={t('service.serviceForm.serviceOwner.placeholder')}
                                dataTestId="service-owner-option"
                            />
                        </Form.Item>

                        <Form.Item
                            label={t('service.serviceForm.companies.title')}
                            name="organizationIds"
                        >
                            <FDMultipleSelection
                                listSelectItem={companiesSelection}
                                placeholder={t('service.serviceForm.companies.placeholder')}
                                dataTestId="company-option"
                            />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </div>
    );
};

ServiceForm.propTypes = {
    open: PropTypes.bool,
    service: PropTypes.object,
    onClose: PropTypes.func
};

export default ServiceForm;
