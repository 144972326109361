import { DeleteOutlined, EditOutlined, PlayCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space } from 'antd';
import Search from 'antd/es/input/Search';
import { FDMultipleSelection, FDTable } from 'common/components';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_GRAY_CLASS,
    BUTTON_GREEN_CLASS,
    SUCCESSFULLY_DELETION_MESSAGE,
    Title
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage, isMatchSearchText } from 'common/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCamera, getCameras, getEnginesSelection } from 'redux/thunks';
import { ENGINE_SERVICE } from 'services';
import Swal from 'sweetalert2';
import CameraForm from './CameraForm';

const Camera = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { data: cameras, loading } = useSelector((s) => s.camera);
    const enginesSelection = useSelector((s) => s.enginesSelection.data);

    const [searchText, setSearchText] = useState('');
    const [selectedEngines, setSelectedEngines] = useState([]);
    const [modalState, setModalState] = useState({ open: false, camera: null });

    const columns = [
        {
            title: 'camera.cameraTable.cameraId',
            dataIndex: 'cameraId',
            key: 'cameraId',
            sorter: {
                compare: (a, b) => a.cameraId.localeCompare(b.cameraId),
                multiple: 1
            }
        },
        {
            title: 'camera.cameraTable.name',
            dataIndex: 'name',
            key: 'name',
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
                multiple: 2
            }
        },
        {
            title: 'camera.cameraTable.description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'camera.cameraTable.engineId',
            dataIndex: 'engineId',
            key: 'engineId',
            sorter: {
                compare: (a, b) => a.engineId.localeCompare(b.engineId),
                multiple: 3
            }
        },
        {
            title: 'camera.cameraTable.engineName',
            dataIndex: 'engineName',
            key: 'engineName',
            sorter: {
                compare: (a, b) => a.engineName.localeCompare(b.engineName),
                multiple: 4
            }
        },
        {
            title: 'camera.cameraTable.configurationJson',
            dataIndex: 'configurationJson',
            key: 'configurationJson'
        },
        {
            title: 'camera.cameraTable.action',
            width: 'auto',
            dataIndex: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button className={BUTTON_GRAY_CLASS} onClick={() => handleResetCamera(record)}>
                        <PlayCircleOutlined />
                        <span>{t('camera.cameraAction.reset')}</span>
                    </Button>

                    <Button
                        className={BUTTON_GRAY_CLASS}
                        onClick={() => setModalState({ open: true, camera: record })}
                    >
                        <EditOutlined />
                        <span>{t('general.action.edit')}</span>
                    </Button>

                    <Button
                        className={BUTTON_GREEN_CLASS}
                        onClick={() => handleDeleteCamera(record?.id)}
                    >
                        <DeleteOutlined />
                        <span>{t('general.action.delete')}</span>
                    </Button>
                </Space>
            )
        }
    ];

    useEffect(() => {
        dispatch(getCameras());
        dispatch(getEnginesSelection());
    }, []);

    const handleDeleteCamera = (id) => {
        Swal.fire({
            title: t('general.action.deleteModalWithoutRevert.title'),
            text: t('general.action.deleteModalWithoutRevert.text'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0369a1',
            cancelButtonColor: '#64748b',
            confirmButtonText: t('general.action.deleteModalWithoutRevert.confirmText'),
            cancelButtonText: t('general.action.deleteModal.cancelText')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(deleteCamera(id)).unwrap();

                    dispatch(getCameras());
                    alertSuccessMessage(t(SUCCESSFULLY_DELETION_MESSAGE));
                } catch (err) {
                    alertErrorMessage(err, t);
                }
            }
        });
    };

    const handleResetCamera = (camera) => {
        Swal.fire({
            title: t('general.action.deleteModalWithoutRevert.title'),
            text: t('general.action.deleteModalWithoutRevert.text'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0369a1',
            cancelButtonColor: '#64748b',
            confirmButtonText: t('camera.cameraAction.reset'),
            cancelButtonText: t('general.action.deleteModal.cancelText')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const configuration = JSON.parse(camera.configurationJson);

                    await ENGINE_SERVICE.resetCamera(configuration.RESET_CAMERA_URL);
                } catch (err) {
                    alertErrorMessage(err, t);
                }
            }
        });
    };

    return (
        <div className="camera-management">
            <Title level={4}>{t('camera.title')}</Title>

            <Button className={BUTTON_BLUE_CLASS} onClick={() => setModalState({ open: true })}>
                <PlusOutlined />
                <span>{t('camera.cameraForm.createCamera')}</span>
            </Button>

            <Row className="md:text-base filter">
                <Col xs={24} lg={12} xl={7} xxl={5}>
                    <div className="font-semibold">{t('camera.filterBar.name.title')}</div>
                    <Search
                        placeholder={t('camera.filterBar.name.placeholder')}
                        onSearch={(searchText) =>
                            setSearchText(searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
                        }
                    />
                </Col>

                <Col xs={24} lg={12} xl={7} xxl={5}>
                    <div className="font-semibold">{t('camera.filterBar.engine.title')}</div>
                    <FDMultipleSelection
                        placeholder={t('camera.filterBar.engine.placeholder')}
                        listSelectItem={enginesSelection}
                        onChange={(engines) => setSelectedEngines(engines)}
                        dataTestId="original-site-option"
                    />
                </Col>
            </Row>

            <FDTable
                headers={columns}
                data={cameras.filter(
                    (camera) =>
                        (isMatchSearchText(camera.cameraId, searchText) ||
                            isMatchSearchText(camera.name, searchText)) &&
                        (!selectedEngines.length || selectedEngines.includes(camera.engineId))
                )}
                loading={loading}
                rowKey="id"
            />

            <CameraForm
                open={modalState.open}
                camera={modalState.camera}
                onClose={() => setModalState({ open: false, camera: null })}
                onReload={() => dispatch(getCameras())}
            />
        </div>
    );
};

export default Camera;
