import { DATE_TIME_FORMAT, Title } from 'common/constants';
import { formatDateTime } from 'common/utils';

export const TABLE_COLUMNS = {
    FT_PROCESS: {
        dataIndex: 'ftProcessId',
        className: 'ftProcessId',
        title: 'process.processTable.ftProcessId',
        sorter: {
            compare: (a, b) => a.ftProcessId.localeCompare(b.ftProcessId),
            multiple: 1
        },
        render: (_, record) => (
            <div className="avatar-info">
                <p>{record.ftProcessId}</p>
                <p>{formatDateTime(record.ftStartTime, DATE_TIME_FORMAT)}</p>
            </div>
        ),
        sortDirections: ['ascend', 'descend']
    },
    FLS_PROCESS: {
        dataIndex: 'flsProcessId',
        className: 'flsProcessId',
        title: 'process.processTable.flsProcessId',
        sorter: {
            compare: (a, b) => a.flsProcessId.localeCompare(b.flsProcessId),
            multiple: 2
        },
        render: (_, record) => (
            <div className="avatar-info">
                <p>{record.flsProcessId}</p>
                <p>{formatDateTime(record.flsStartTime, DATE_TIME_FORMAT)}</p>
            </div>
        ),
        sortDirections: ['ascend', 'descend']
    },
    PROCESS: {
        dataIndex: 'processId',
        className: 'processId',
        title: 'process.processTable.flsProcessId',
        sorter: {
            compare: (a, b) => a.processId.localeCompare(b.processId),
            multiple: 2
        },
        render: (_, record) => (
            <div className="avatar-info">
                <p>{record.processId}</p>
                <p>{formatDateTime(record.startTime, DATE_TIME_FORMAT)}</p>
            </div>
        ),
        sortDirections: ['ascend', 'descend']
    },
    FARMER_COMPANY_NAME: {
        dataIndex: 'farmerOrganizationName',
        className: 'farmerOrganizationName',
        title: 'process.processTable.farmerOrganizationName',
        sorter: {
            compare: (a, b) => a.farmerOrganizationName.localeCompare(b.farmerOrganizationName),
            multiple: 3
        },
        sortDirections: ['ascend', 'descend']
    },
    SITE_NAME: {
        dataIndex: 'siteName',
        className: 'siteName',
        title: 'process.processTable.siteName',
        sorter: {
            compare: (a, b) => a.siteName.localeCompare(b.siteName),
            multiple: 4
        },
        sortDirections: ['ascend', 'descend']
    },
    PEN_NUMBER: {
        dataIndex: 'penNumber',
        className: 'penNumber',
        title: 'process.processTable.penNumber',
        sorter: {
            compare: (a, b) => a.penNumber.localeCompare(b.penNumber),
            multiple: 5
        },
        sortDirections: ['ascend', 'descend']
    },
    BOAT_NAME: {
        dataIndex: 'boatName',
        className: 'boatName',
        title: 'process.processTable.boatName',
        sorter: {
            compare: (a, b) => a.boatName.localeCompare(b.boatName),
            multiple: 6
        },
        render: (_, record) => (
            <div className="avatar-info">
                <Title level={5}>{record.boatName}</Title>
                <p>{record.serviceBoatOrganizationName}</p>
            </div>
        ),
        sortDirections: ['ascend', 'descend']
    }
};
