import {
    DeleteOutlined,
    EditOutlined,
    GlobalOutlined,
    PlusOutlined,
    RestOutlined,
    RollbackOutlined
} from '@ant-design/icons';
import { Button, Col, Row, Space, Spin, Tabs } from 'antd';
import { FDTable } from 'common/components';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_GRAY_CLASS,
    BUTTON_GREEN_CLASS,
    SUCCESSFULLY_DELETION_MESSAGE
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSite, getSites, getSitesContainingPens } from 'redux/thunks';
import Swal from 'sweetalert2';
import RestoreSite from './RestoreSite';
import SiteForm from './SiteForm';

const Site = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { data: sites = [], loading = false } = useSelector((s) => s.site || {});

    const [isDeleteTab, setIsDeleteTab] = useState(false);
    const [modalState, setModalState] = useState({
        upsertFormOpen: false,
        restoreFormOpen: false,
        site: null
    });

    const columns = [
        {
            dataIndex: 'siteName',
            className: 'siteName',
            title: 'site.siteTable.name',
            sorter: {
                compare: (a, b) => a.siteName.localeCompare(b.siteName),
                multiple: 1
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            dataIndex: 'description',
            className: 'description',
            title: 'site.siteTable.description'
        },
        {
            dataIndex: 'companyName',
            title: 'site.siteTable.company'
        },
        {
            dataIndex: 'latitude',
            className: 'latitude',
            title: 'site.siteTable.latitude'
        },
        {
            dataIndex: 'longitude',
            className: 'longitude',
            title: 'site.siteTable.longitude'
        },
        {
            dataIndex: 'localityNumber',
            className: 'locationNumber',
            title: 'site.siteTable.locationNumber',
            sorter: {
                compare: (a, b) => a.localityNumber - b.localityNumber,
                multiple: 2
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            dataIndex: 'capacity',
            className: 'capacity',
            title: 'site.siteTable.capacity'
        },
        {
            dataIndex: 'municipality',
            className: 'municipality',
            title: 'site.siteTable.municipality'
        },
        {
            dataIndex: 'address',
            className: 'address',
            title: 'site.siteTable.address'
        },
        {
            dataIndex: 'action',
            className: 'action',
            title: 'site.siteTable.action',
            render: (_, record) => (
                <Space size="middle">
                    {isDeleteTab ? (
                        <Button
                            className={`restore-site ${BUTTON_GRAY_CLASS}`}
                            onClick={() => handleShowRestoreModal(record)}
                        >
                            <RollbackOutlined />
                            <span>{t('general.action.restore')}</span>
                        </Button>
                    ) : (
                        <>
                            <Button
                                className={`edit-site ${BUTTON_GRAY_CLASS}`}
                                onClick={() => handleShowModal(record)}
                            >
                                <EditOutlined />
                                <span>{t('general.action.edit')}</span>
                            </Button>
                            <Button
                                className={`delete-site ${BUTTON_GREEN_CLASS}`}
                                onClick={() => handleDeleteSite(record.id)}
                            >
                                <DeleteOutlined />
                                <span>{t('general.action.delete')}</span>
                            </Button>
                        </>
                    )}
                </Space>
            )
        }
    ];

    useEffect(() => {
        dispatch(getSites({ isDeleted: isDeleteTab }));
    }, [isDeleteTab]);

    const handleTabChange = (value) => setIsDeleteTab(value);

    const handleShowModal = (site) => {
        setModalState({
            upsertFormOpen: true,
            restoreFormOpen: false,
            site
        });
    };

    const handleShowRestoreModal = (site) => {
        setModalState({
            upsertFormOpen: false,
            restoreFormOpen: true,
            site
        });
    };

    const handleClose = () => {
        setModalState({
            upsertFormOpen: false,
            restoreFormOpen: false,
            site: null
        });
    };

    const handleDeleteSite = (id) => {
        Swal.fire({
            title: t('general.action.deleteModal.title'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0369a1',
            cancelButtonColor: '#64748b',
            confirmButtonText: t('general.action.deleteModal.confirmText'),
            cancelButtonText: t('general.action.deleteModal.cancelText')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(deleteSite(id)).unwrap();

                    dispatch(getSites({ isDeleted: isDeleteTab }));
                    dispatch(getSitesContainingPens());
                    alertSuccessMessage(t(SUCCESSFULLY_DELETION_MESSAGE));
                } catch (err) {
                    alertErrorMessage(err, t);
                }
            }
        });
    };

    return (
        <>
            <h2 className="mb-2">{t('site.title')}</h2>
            <Row justify="center">
                <Col span={24}>
                    <Button
                        className={`new-site ${BUTTON_BLUE_CLASS}`}
                        onClick={() => handleShowModal(null)}
                    >
                        <PlusOutlined />
                        <span>{t('site.newSiteBtn')}</span>
                    </Button>

                    <Spin spinning={loading}>
                        <Tabs
                            className="site-management-tabs"
                            activeKey={isDeleteTab}
                            animated={true}
                            onChange={handleTabChange}
                            items={[
                                {
                                    label: (
                                        <span className="active-sites">
                                            <GlobalOutlined />
                                            <span>{t('site.activeSites')}</span>
                                        </span>
                                    ),
                                    key: false,
                                    children: (
                                        <FDTable
                                            className="site-table"
                                            headers={columns}
                                            data={sites}
                                            rowKey="id"
                                        />
                                    )
                                },
                                {
                                    label: (
                                        <span className="deleted-sites">
                                            <RestOutlined />
                                            <span>{t('site.deletedSites')}</span>
                                        </span>
                                    ),
                                    key: true,
                                    children: (
                                        <FDTable
                                            className="site-deleted-table"
                                            headers={columns}
                                            data={sites}
                                            rowKey="id"
                                        />
                                    )
                                }
                            ]}
                        />
                    </Spin>
                </Col>
            </Row>

            <SiteForm
                site={modalState.site}
                open={modalState.upsertFormOpen}
                onClose={handleClose}
                onReload={() => handleTabChange(false)}
            />

            <RestoreSite
                site={modalState.site}
                open={modalState.restoreFormOpen}
                onClose={handleClose}
            />
        </>
    );
};

export default Site;
