import { createSlice } from '@reduxjs/toolkit';
import { getHeaderTenants, updateCurrentTenantId } from 'redux/thunks';

const initialState = {
    data: {
        tenants: [],
        currentTenantId: null
    },
    loading: false
};

export const headerTenantSlice = createSlice({
    name: 'tenant',
    initialState,
    reducers: {},
    extraReducers: {
        [getHeaderTenants.pending]: (state) => {
            state.loading = true;
        },
        [getHeaderTenants.fulfilled]: (state, action) => {
            state.loading = false;
            state.data.tenants = action.payload || initialState.data.tenants;
        },
        [getHeaderTenants.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        },

        [updateCurrentTenantId.pending]: (state) => {
            state.loading = true;
        },
        [updateCurrentTenantId.fulfilled]: (state, action) => {
            state.loading = false;
            state.data.currentTenantId = action.payload || initialState.data.currentTenantId;
        },
        [updateCurrentTenantId.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        }
    }
});
