import { createSlice } from '@reduxjs/toolkit';
import { getEngines, createEngine, updateEngine, deleteEngine } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const engineSlice = createSlice({
    name: 'engine',
    initialState,
    reducers: {},
    extraReducers: {
        [getEngines.pending]: (state) => {
            state.loading = true;
        },
        [getEngines.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        [getEngines.rejected]: (state) => {
            state.loading = false;
        },

        [createEngine.pending]: (state) => {
            state.loading = true;
        },
        [createEngine.fulfilled]: (state) => {
            state.loading = false;
        },
        [createEngine.rejected]: (state) => {
            state.loading = false;
        },

        [updateEngine.pending]: (state) => {
            state.loading = true;
        },
        [updateEngine.fulfilled]: (state) => {
            state.loading = false;
        },
        [updateEngine.rejected]: (state) => {
            state.loading = false;
        },

        [deleteEngine.pending]: (state) => {
            state.loading = true;
        },
        [deleteEngine.fulfilled]: (state) => {
            state.loading = false;
        },
        [deleteEngine.rejected]: (state) => {
            state.loading = false;
        }
    }
});
