import { Button, Modal } from 'antd';
import { FDTable } from 'common/components';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getFTProcesses, linkProcess } from 'redux/thunks';
import { TABLE_COLUMNS } from '../ProcessColumn';

const ProcessForm = ({ open = false, process, onClose = () => {} }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const initialFilters = useSelector((s) => s.filter.data);
    const { data: processes, loading } = useSelector((s) => s.flsProcess);

    const [selectedProcess, setSelectedProcess] = useState([]);

    useEffect(() => {
        setSelectedProcess([{ ...process, processId: process?.flsProcessId }]);
    }, [process]);

    const handleLinkProcess = async () => {
        try {
            const response = await dispatch(
                linkProcess({
                    ...selectedProcess[0],
                    ftProcessId: process.ftProcessId,
                    flsProcessId: selectedProcess[0].processId,
                    flsStartTime: selectedProcess[0].startTime
                })
            ).unwrap();

            dispatch(
                getFTProcesses({
                    farmerOrganizationIds: initialFilters.farmerOrganizationIds,
                    farmerSites: initialFilters.farmerSites,
                    farmerPens: initialFilters.farmerPens,
                    serviceBoatOrganizationIds: initialFilters.serviceBoatOrganizationIds,
                    externalBoatIds: initialFilters.externalBoatIds,
                    fromDate: initialFilters.fromDate,
                    toDate: initialFilters.toDate
                })
            );
            alertSuccessMessage(t(response));
            onClose();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const formatData = (data) => {
        data = data
            .filter(
                (item) =>
                    item.serviceBoatOrganizationId === process?.serviceBoatOrganizationId &&
                    item.farmerOrganizationId === process?.farmerOrganizationId &&
                    item.localityNumber === process?.localityNumber &&
                    item.externalBoatId === process?.externalBoatId
            )
            .map((process) => ({
                ...process,
                key: process.processId
            }));

        const index = data.findIndex((obj) => obj.key === process?.flsProcessId);

        if (index > -1) data.unshift(...data.splice(index, 1));

        return data;
    };

    return (
        <Modal
            title={t('process.processForm.title')}
            bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 180px)' }}
            style={{ top: 30 }}
            open={open}
            onCancel={onClose}
            width={1000}
            footer={
                <>
                    <Button type="default" onClick={onClose} className="m-1">
                        {t('general.form.cancel')}
                    </Button>
                    <Button
                        disabled={selectedProcess[0]?.processId === process?.flsProcessId}
                        onClick={handleLinkProcess}
                        className="m-1 bg-sky-700 hover:bg-sky-800 text-white hover:text-white"
                    >
                        {t('general.form.saveChange')}
                    </Button>
                </>
            }
        >
            <FDTable
                loading={loading}
                headers={[
                    TABLE_COLUMNS.PROCESS,
                    TABLE_COLUMNS.FARMER_COMPANY_NAME,
                    TABLE_COLUMNS.SITE_NAME,
                    TABLE_COLUMNS.PEN_NUMBER,
                    TABLE_COLUMNS.BOAT_NAME
                ]}
                data={formatData(processes)}
                rowSelection={{
                    type: 'radio',
                    selectedRowKeys: selectedProcess.map((process) => process?.processId),
                    onChange: (selectedProcessKeys, selectedProcess) => {
                        setSelectedProcess(selectedProcess);
                    }
                }}
            />
        </Modal>
    );
};

export default ProcessForm;
