import { BoxPlotOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space, Spin, Tabs } from 'antd';
import { FDTable } from 'common/components';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_GRAY_CLASS,
    BUTTON_GREEN_CLASS,
    ENGINE_LOCATION,
    SUCCESSFULLY_CREATION_MESSAGE,
    SUCCESSFULLY_DELETION_MESSAGE,
    SUCCESSFULLY_EDIT_MESSAGE,
    Title
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createEngine, deleteEngine, getEngines, updateEngine } from 'redux/thunks';
import Swal from 'sweetalert2';
import EngineForm from './EngineForm';

const Engine = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { data: engines, loading } = useSelector((s) => s.engine);

    const [engineTab, setEngineTab] = useState(ENGINE_LOCATION.DEAD_FISH_DETECTION_ENGINE_IN_SITE);
    const [engineModalState, setEngineModalState] = useState({
        location: null,
        engine: null
    });

    const tableColumns = (externalColumnTitle) => [
        {
            title: 'engine.engineTable.engineId',
            dataIndex: 'engineId',
            key: 'engineId',
            sorter: {
                compare: (a, b) => a.engineId.localeCompare(b.engineId),
                multiple: 1
            }
        },
        {
            title: 'engine.engineTable.name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: externalColumnTitle,
            dataIndex: 'externalId',
            key: 'externalId'
        },
        {
            title: 'engine.engineTable.description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'engine.engineTable.configurationJson',
            dataIndex: 'configurationJson',
            key: 'configurationJson'
        },
        {
            title: 'engine.engineTable.action',
            dataIndex: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        className={BUTTON_GRAY_CLASS}
                        onClick={() => setEngineModalState({ location: engineTab, engine: record })}
                    >
                        <EditOutlined />
                        <span>{t('general.action.edit')}</span>
                    </Button>
                    <Button
                        className={BUTTON_GREEN_CLASS}
                        onClick={() => handleDeleteEngine(record?.id)}
                    >
                        <DeleteOutlined />
                        <span>{t('general.action.delete')}</span>
                    </Button>
                </Space>
            )
        }
    ];

    const createOptions = [
        {
            label: <Trans i18nKey="engine.tab.deadFishDetectionEngineInSite" />,
            key: 'deadFishDetectionEngineInSite',
            icon: <BoxPlotOutlined />,
            onClick: () =>
                setEngineModalState({
                    location: ENGINE_LOCATION.DEAD_FISH_DETECTION_ENGINE_IN_SITE,
                    engine: null
                })
        },
        {
            label: <Trans i18nKey="engine.tab.deadFishDetectionEngineInFisheryBoat" />,
            key: 'deadFishDetectionEngineInFisheryBoat',
            icon: <BoxPlotOutlined />,
            onClick: () =>
                setEngineModalState({
                    location: ENGINE_LOCATION.DEAD_FISH_DETECTION_ENGINE_IN_FISHERY_BOAT,
                    engine: null
                })
        },
        {
            label: <Trans i18nKey="engine.tab.stressDetectionEngineInServiceBoat" />,
            key: 'stressDetectionEngineInServiceBoat',
            icon: <BoxPlotOutlined />,
            onClick: () =>
                setEngineModalState({
                    location: ENGINE_LOCATION.STRESS_DETECTION_ENGINE_IN_SERVICE_BOAT,
                    engine: null
                })
        },
        {
            label: <Trans i18nKey="engine.tab.flowDetectionEngineInServiceBoat" />,
            key: 'flowDetectionEngineInServiceBoat',
            icon: <BoxPlotOutlined />,
            onClick: () =>
                setEngineModalState({
                    location: ENGINE_LOCATION.FLOW_DETECTION_ENGINE_IN_SERVICE_BOAT,
                    engine: null
                })
        },
        {
            label: <Trans i18nKey="engine.tab.rtdeEngineInServiceBoat" />,
            key: 'rtdeEngineInServiceBoat',
            icon: <BoxPlotOutlined />,
            onClick: () =>
                setEngineModalState({
                    location: ENGINE_LOCATION.RTDE_ENGINE_IN_SERVICE_BOAT,
                    engine: null
                })
        }
    ];

    useEffect(() => {
        dispatch(getEngines({ engineLocation: engineTab }));
    }, [engineTab]);

    const handleCreateEngine = async (req) => {
        try {
            await dispatch(createEngine(req)).unwrap();

            dispatch(getEngines({ engineLocation: engineTab }));

            alertSuccessMessage(t(SUCCESSFULLY_CREATION_MESSAGE));

            setEngineModalState({ location: null, engine: null });
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const handleUpdateEngine = async (id, req) => {
        try {
            await dispatch(updateEngine({ id, item: req })).unwrap();

            dispatch(getEngines({ engineLocation: engineTab }));

            alertSuccessMessage(t(SUCCESSFULLY_EDIT_MESSAGE));

            setEngineModalState({ location: null, engine: null });
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const handleDeleteEngine = (id) => {
        Swal.fire({
            title: t('general.action.deleteModalWithoutRevert.title'),
            text: t('general.action.deleteModalWithoutRevert.text'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0369a1',
            cancelButtonColor: '#64748b',
            confirmButtonText: t('general.action.deleteModalWithoutRevert.confirmText'),
            cancelButtonText: t('general.action.deleteModal.cancelText')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(deleteEngine(id)).unwrap();

                    dispatch(getEngines({ engineLocation: engineTab }));
                    alertSuccessMessage(t(SUCCESSFULLY_DELETION_MESSAGE));
                } catch (err) {
                    alertErrorMessage(err, t);
                }
            }
        });
    };

    return (
        <>
            <Title level={4}>{t('engine.title')}</Title>
            <div>
                <Dropdown placement="right" arrow menu={{ items: createOptions }}>
                    <Button type="default" shape="round" className={BUTTON_BLUE_CLASS}>
                        <PlusOutlined />
                        <span>{t('engine.newEngineBtn')}</span>
                    </Button>
                </Dropdown>

                <Spin spinning={loading}>
                    <Tabs
                        className="engine-tabs"
                        activeKey={engineTab}
                        animated={true}
                        onChange={(value) => setEngineTab(value)}
                        items={[
                            {
                                label: (
                                    <div className="flex">
                                        <BoxPlotOutlined />
                                        <Trans i18nKey="engine.tab.deadFishDetectionEngineInSite" />
                                    </div>
                                ),
                                key: ENGINE_LOCATION.DEAD_FISH_DETECTION_ENGINE_IN_SITE,
                                children: (
                                    <FDTable
                                        className="dead-fish-detection-engine-in-site-table"
                                        headers={tableColumns('engine.engineTable.localityNumber')}
                                        data={engines}
                                        rowKey="id"
                                    />
                                )
                            },
                            {
                                label: (
                                    <div className="flex">
                                        <BoxPlotOutlined />
                                        <Trans i18nKey="engine.tab.deadFishDetectionEngineInFisheryBoat" />
                                    </div>
                                ),
                                key: ENGINE_LOCATION.DEAD_FISH_DETECTION_ENGINE_IN_FISHERY_BOAT,
                                children: (
                                    <FDTable
                                        className="dead-fish-detection-engine-in-fishery-boat-table"
                                        headers={tableColumns(
                                            'engine.engineTable.externalFisheryBoatId'
                                        )}
                                        data={engines}
                                        rowKey="id"
                                    />
                                )
                            },
                            {
                                label: (
                                    <div className="flex">
                                        <BoxPlotOutlined />
                                        <Trans i18nKey="engine.tab.stressDetectionEngineInServiceBoat" />
                                    </div>
                                ),
                                key: ENGINE_LOCATION.STRESS_DETECTION_ENGINE_IN_SERVICE_BOAT,
                                children: (
                                    <FDTable
                                        className="stress-detection-engine-in-service-boat-table"
                                        headers={tableColumns(
                                            'engine.engineTable.externalServiceBoatId'
                                        )}
                                        data={engines}
                                        rowKey="id"
                                    />
                                )
                            },
                            {
                                label: (
                                    <div className="flex">
                                        <BoxPlotOutlined />
                                        <Trans i18nKey="engine.tab.flowDetectionEngineInServiceBoat" />
                                    </div>
                                ),
                                key: ENGINE_LOCATION.FLOW_DETECTION_ENGINE_IN_SERVICE_BOAT,
                                children: (
                                    <FDTable
                                        className="flow-detection-engine-in-service-boat-table"
                                        headers={tableColumns(
                                            'engine.engineTable.externalServiceBoatId'
                                        )}
                                        data={engines}
                                        rowKey="id"
                                    />
                                )
                            },
                            {
                                label: (
                                    <div className="flex">
                                        <BoxPlotOutlined />
                                        <Trans i18nKey="engine.tab.rtdeEngineInServiceBoat" />
                                    </div>
                                ),
                                key: ENGINE_LOCATION.RTDE_ENGINE_IN_SERVICE_BOAT,
                                children: (
                                    <FDTable
                                        className="rtde-engine-in-service-boat-table"
                                        headers={tableColumns(
                                            'engine.engineTable.externalServiceBoatId'
                                        )}
                                        data={engines}
                                        rowKey="id"
                                    />
                                )
                            }
                        ]}
                    />
                </Spin>
            </div>

            <EngineForm
                location={engineModalState.location}
                engine={engineModalState.engine}
                onCreate={handleCreateEngine}
                onUpdate={handleUpdateEngine}
                onClose={() => setEngineModalState({ location: null, engine: null })}
            />
        </>
    );
};

export default Engine;
