import { FDSelection } from 'common/components';
import { TENANT_HANDLING_PAGE } from 'common/constants';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const TenantSelection = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { data: tenantInfo = {}, loading = false } = useSelector((s) => s.headerTenant);

    const handleTenantChange = (tenantId) => {
        localStorage.setItem('tenantId', tenantId);

        navigate(TENANT_HANDLING_PAGE, { state: { navigateUrl: location.pathname } });
    };

    return (
        <FDSelection
            listSelectItem={tenantInfo.tenants}
            placeholder="Select tenant"
            className="min-w-[180px]"
            loading={loading}
            defaultValue={tenantInfo.currentTenantId}
            onChange={handleTenantChange}
        />
    );
};

export default TenantSelection;
