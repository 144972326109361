import { createAsyncThunk } from '@reduxjs/toolkit';
import { COMPANY_API } from 'common/constants';
import { API } from 'services';

export const getCompanies = createAsyncThunk(
    'company/getCompanies',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(COMPANY_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const createCompany = createAsyncThunk(
    'company/createCompany',
    async (item, { rejectWithValue }) => {
        try {
            const { data } = await API.post(COMPANY_API, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const updateCompany = createAsyncThunk(
    'company/updateCompany',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${COMPANY_API}/${id}`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
