import { AppstoreOutlined, DribbbleOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSitesContainingPens } from 'redux/thunks';
import PropTypes from 'prop-types';
import { siteSlice } from 'redux/slices';

const SiteList = ({ className = '' }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { data: sites = [] } = useSelector((s) => s.sitePen || {});

    useEffect(() => {
        dispatch(getSitesContainingPens());
    }, []);

    return (
        <div className={`${className} min-h-[64px]`}>
            <Menu mode="horizontal" selectable={false} className="header-site-menu border-none">
                {sites.map((site) => (
                    <Menu.SubMenu
                        key={site.id}
                        title={
                            <div className="flex items-center">
                                <AppstoreOutlined />
                                <span>{site.siteName}</span>
                            </div>
                        }
                        disabled={!site.hasData}
                        className="flex items-center"
                    >
                        <Menu.Item key={'View site' + site.id} className="header-site-menu-item">
                            <Link rel="noopener noreferrer" className="flex items-center">
                                <AppstoreOutlined /> <span>{t('layout.header.viewSite')}</span>
                            </Link>
                        </Menu.Item>

                        {site.pens?.map((pen) => (
                            <Menu.Item
                                className="header-site-menu-item"
                                key={pen.id}
                                disabled={!pen.hasData}
                            >
                                <Link rel="noopener noreferrer" className="flex items-center">
                                    <DribbbleOutlined /> <span>{pen.penNumber}</span>
                                </Link>
                            </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                ))}
            </Menu>
        </div>
    );
};

siteSlice.PropTypes = {
    className: PropTypes.string
};

export default SiteList;
