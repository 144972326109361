import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { updateFilters } from 'redux/thunks';

const initialState = {
    data: {
        farmerOrganizationIds: [],
        farmerSites: [],
        farmerPens: [],
        serviceBoatOrganizationIds: [],
        externalBoatIds: [],
        fromDate: moment().startOf('day').subtract(6, 'days'),
        toDate: moment().endOf('day')
    },
    loading: false
};

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {},
    extraReducers: {
        [updateFilters.pending]: (state) => {
            state.loading = true;
        },
        [updateFilters.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
        },
        [updateFilters.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        }
    }
});
