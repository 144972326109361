import { MenuOutlined } from '@ant-design/icons';
import { Layout as LibLayout } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCurrentProfile } from 'redux/thunks';
import CountryPicker from './CountryPicker';
import DropdownProfile from './DropdownProfile';
import './Header.scss';
import ServicesDrawer from './ServicesDrawer';
import SiteList from './SiteList';
import TenantSelection from './TenantSelection';

const Header = () => {
    const { Header } = LibLayout;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCurrentProfile());
    });

    const handleToggleHeaderItems = () => {
        const headerItems = document.getElementsByClassName('header-items')[0];
        headerItems.classList.toggle('xs:hidden');
        headerItems.classList.toggle('xs:flex');
    };

    return (
        <Header
            theme="dark"
            className="header h-auto z-10 flex justify-between items-start fixed top-0 left-0 right-0 
                xs:ml-0 xs:px-0 xs:flex-col md:ml-[250px] md:pl-2 md:pr-6 lg:flex-row"
        >
            <SiteList className="xs:w-5/6 lg:w-2/5" />

            <div className="toggle-button absolute right-5 lg:hidden">
                <MenuOutlined
                    className="text-xl cursor-pointer"
                    onClick={handleToggleHeaderItems}
                />
            </div>

            <div
                className="header-items w-full gap-x-5 items-center justify-center 
                    xs:hidden xs:flex-wrap lg:flex lg:justify-end lg:flex-nowrap lg:w-3/5"
            >
                <TenantSelection />

                <CountryPicker />

                <DropdownProfile />

                <ServicesDrawer />
            </div>
        </Header>
    );
};

export default Header;
