export const SERVICE_STATUS = [
    {
        id: 'Released',
        text: 'service.serviceStatus.released'
    },
    {
        id: 'Unreleased',
        text: 'service.serviceStatus.unreleased'
    }
];

export const DEFAULT_SERVICE_STATUS = SERVICE_STATUS[1];
