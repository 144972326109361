import { createAsyncThunk } from '@reduxjs/toolkit';
import { PEN_API } from 'common/constants';
import { API } from 'services';

export const getPens = createAsyncThunk(
    'pens/getPens',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.get(PEN_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const createPen = createAsyncThunk('pens/createPen', async (item, { rejectWithValue }) => {
    try {
        const { data } = await API.post(PEN_API, item);
        return data;
    } catch (err) {
        return rejectWithValue(err);
    }
});

export const updatePen = createAsyncThunk(
    'pens/updatePen',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${PEN_API}/${id}`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const deletePen = createAsyncThunk('pens/deletePen', async (id, { rejectWithValue }) => {
    try {
        const { data } = await API.delete(`${PEN_API}/${id}`);
        return data;
    } catch (err) {
        return rejectWithValue(err);
    }
});

export const restorePen = createAsyncThunk('pens/restorePen', async (id, { rejectWithValue }) => {
    try {
        const { data } = await API.put(`${PEN_API}/${id}/restore`);
        return data;
    } catch (err) {
        return rejectWithValue(err);
    }
});
