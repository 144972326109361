import { Button, Col, Form, Input, Modal, Row, Spin } from 'antd';
import { FDMultipleSelection, FDUploadImage } from 'common/components';
import {
    COMPANY_TYPE,
    DEFAULT_FIRST_AVERAGE_NOTIFICATION,
    DEFAULT_FIRST_MORTALITY_LEVEL,
    DEFAULT_FIRST_MORTALITY_TREND,
    DEFAULT_SECOND_AVERAGE_NOTIFICATION,
    DEFAULT_SECOND_MORTALITY_LEVEL,
    DEFAULT_SECOND_MORTALITY_TREND,
    SUCCESSFULLY_CREATION_MESSAGE,
    SUCCESSFULLY_EDIT_MESSAGE
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage, normFile } from 'common/utils';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    createCompany,
    getCompanies,
    getCompaniesSelection,
    getCompanyTypesSelection,
    getOriginalSitesSelection,
    getServicesSelection,
    updateCompany
} from 'redux/thunks';
import MortalityConfig from './MortalityConfig';
import { createCompanyConfigs, extractCompanyConfig } from './MortalityConfigUtils';
import NotificationConfig from './NotificationConfig';

const CompanyForm = ({ open = false, company, onClose = () => {} }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const upsertLoading = useSelector((s) => s.company.loading || false);
    const servicesSelection = useSelector((s) => s.serviceSelection.data || []);
    const originalSitesSelection = useSelector((s) => s.originalSiteSelection.data || []);
    const companiesSelection = useSelector((s) => s.companySelection.data || []);
    const companyTypesSelection = useSelector((s) => s.companyTypeSelection.data || []);

    const companyTypeNames = Form.useWatch('companyTypeNames', form);

    const isUpdateForm = !!company;

    useEffect(() => {
        dispatch(getOriginalSitesSelection());
        dispatch(getServicesSelection());
        dispatch(
            getCompaniesSelection({
                isFilterByAzureTenantId: false,
                companyTypes: [COMPANY_TYPE.FARMER]
            })
        );
        dispatch(getCompanyTypesSelection());
    }, []);

    useEffect(() => {
        if (isUpdateForm) {
            const companyConfig = extractCompanyConfig(company);
            form.setFieldsValue({
                ...company,
                ...companyConfig
            });
        } else {
            form.resetFields();
        }
    }, [company]);

    const createOrUpdateCompany = (req) => {
        if (isUpdateForm) {
            handleUpdateCompany(req);
        } else {
            handleCreateCompany(req);
        }
    };

    const handleCreateCompany = async (req) => {
        req.companyConfigs = createCompanyConfigs(req);

        try {
            await dispatch(createCompany(req)).unwrap();

            dispatch(getCompanies());
            alertSuccessMessage(t(SUCCESSFULLY_CREATION_MESSAGE));
            form.resetFields();
            onClose();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const handleUpdateCompany = async (req) => {
        req.companyConfigs = createCompanyConfigs(req);

        try {
            await dispatch(updateCompany({ id: company.id, item: req })).unwrap();

            dispatch(getCompanies());
            alertSuccessMessage(t(SUCCESSFULLY_EDIT_MESSAGE));
            onClose();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const handleUploadLogo = (url) => form.setFieldValue('logoUrl', url);

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <div className="modal-create-update-company">
            <Modal
                title={
                    isUpdateForm
                        ? t('company.companyForm.updateCompany')
                        : t('company.companyForm.createCompany')
                }
                open={open}
                onCancel={handleCancel}
                width={1300}
                footer={
                    <>
                        <Button type="default" onClick={handleCancel} className="m-1">
                            {t('general.form.cancel')}
                        </Button>
                        <Button
                            onClick={form.submit}
                            className="m-1 bg-sky-700 hover:bg-sky-800 text-white hover:text-white"
                            data-testid="company-ok-button"
                        >
                            {isUpdateForm ? t('general.form.saveChange') : t('general.form.create')}
                        </Button>
                    </>
                }
            >
                <Spin spinning={upsertLoading}>
                    <Form
                        name="basic"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 16, offset: 1 }}
                        onFinish={createOrUpdateCompany}
                        autoComplete="off"
                        form={form}
                        initialValues={{
                            firstMortalityLevel: DEFAULT_FIRST_MORTALITY_LEVEL,
                            secondMortalityLevel: DEFAULT_SECOND_MORTALITY_LEVEL,
                            firstMortalityTrend: DEFAULT_FIRST_MORTALITY_TREND,
                            secondMortalityTrend: DEFAULT_SECOND_MORTALITY_TREND,
                            firstAverageNotification: DEFAULT_FIRST_AVERAGE_NOTIFICATION,
                            secondAverageNotification: DEFAULT_SECOND_AVERAGE_NOTIFICATION
                        }}
                    >
                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={24} lg={11} xl={9}>
                                <Form.Item
                                    name="name"
                                    label={t('company.companyForm.name.title')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('company.companyForm.name.required')
                                        }
                                    ]}
                                >
                                    <Input
                                        placeholder={t('company.companyForm.name.placeholder')}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="logoUrl"
                                    label={t('company.companyForm.logo.title')}
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    extra="*.png, *.jpg"
                                >
                                    <FDUploadImage
                                        url={isUpdateForm && company.logoUrl}
                                        uploadedHandler={handleUploadLogo}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="description"
                                    label={t('company.companyForm.description.title')}
                                >
                                    <Input
                                        placeholder={t(
                                            'company.companyForm.description.placeholder'
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="email"
                                    label={t('company.companyForm.email.title')}
                                >
                                    <Input
                                        placeholder={t('company.companyForm.email.placeholder')}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="phoneNumber"
                                    label={t('company.companyForm.phoneNumber.title')}
                                >
                                    <Input
                                        placeholder={t(
                                            'company.companyForm.phoneNumber.placeholder'
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="address"
                                    label={t('company.companyForm.address.title')}
                                >
                                    <Input
                                        placeholder={t('company.companyForm.address.placeholder')}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="organizationId"
                                    label={t('company.companyForm.organizationId.title')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t(
                                                'company.companyForm.organizationId.required'
                                            )
                                        }
                                    ]}
                                >
                                    <Input
                                        placeholder={t(
                                            'company.companyForm.organizationId.placeholder'
                                        )}
                                        disabled={isUpdateForm}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={13} xl={15}>
                                <Form.Item
                                    label={t('company.companyForm.originalSite.title')}
                                    name="localityNumbers"
                                    className="localityNumbers"
                                    hidden={isUpdateForm}
                                >
                                    <FDMultipleSelection
                                        placeholder={t(
                                            'company.companyForm.originalSite.placeholder'
                                        )}
                                        listSelectItem={originalSitesSelection}
                                        disabled={isUpdateForm}
                                        dataTestId="original-site-option"
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={t('company.companyForm.services.title')}
                                    name="externalServiceIds"
                                >
                                    <FDMultipleSelection
                                        placeholder={t('company.companyForm.services.placeholder')}
                                        listSelectItem={servicesSelection}
                                        dataTestId="service-option"
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={t('company.companyForm.types.title')}
                                    name="companyTypeNames"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('company.companyForm.types.required')
                                        }
                                    ]}
                                    className="types"
                                >
                                    <FDMultipleSelection
                                        placeholder={t('company.companyForm.types.placeholder')}
                                        listSelectItem={companyTypesSelection}
                                        dataTestId="types-option"
                                        disabled={isUpdateForm}
                                    />
                                </Form.Item>

                                {companyTypeNames?.includes(COMPANY_TYPE.SERVICE_BOAT) && (
                                    <Form.Item
                                        label={t('company.companyForm.farmerCompanies.title')}
                                        name="farmerOrganizationIds"
                                        className="farmerOrganizationIds"
                                    >
                                        <FDMultipleSelection
                                            placeholder={t(
                                                'company.companyForm.farmerCompanies.placeholder'
                                            )}
                                            listSelectItem={companiesSelection}
                                            dataTestId="farmer-companies-option"
                                        />
                                    </Form.Item>
                                )}

                                {companyTypeNames?.includes(COMPANY_TYPE.FARMER) && (
                                    <>
                                        <MortalityConfig />

                                        <NotificationConfig />
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </div>
    );
};

CompanyForm.propTypes = {
    open: PropTypes.bool,
    company: PropTypes.object,
    onClose: PropTypes.func
};

export default CompanyForm;
