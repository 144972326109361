import { createAsyncThunk } from '@reduxjs/toolkit';
import { SERVICE_BOAT_API } from 'common/constants';
import { API } from 'services';

export const getServiceBoats = createAsyncThunk(
    'serviceBoats/getServiceBoats',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.get(SERVICE_BOAT_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getOnBoardingServiceBoats = createAsyncThunk(
    'site/getOnBoardingServiceBoats',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${SERVICE_BOAT_API}/on-boarding`, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const createServiceBoat = createAsyncThunk(
    'serviceBoats/createServiceBoat',
    async (item, { rejectWithValue }) => {
        try {
            const { data } = await API.post(SERVICE_BOAT_API, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const updateServiceBoat = createAsyncThunk(
    'serviceBoats/updateServiceBoat',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${SERVICE_BOAT_API}/${id}`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const deleteServiceBoat = createAsyncThunk(
    'serviceBoats/deleteServiceBoat',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.delete(`${SERVICE_BOAT_API}/${id}`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const restoreServiceBoat = createAsyncThunk(
    'serviceBoats/restoreServiceBoat',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${SERVICE_BOAT_API}/restore`, { id });
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
