import { createSlice } from '@reduxjs/toolkit';
import { createCamera, deleteCamera, getCameras, resetCamera, updateCamera } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const cameraSlice = createSlice({
    name: 'camera',
    initialState,
    reducers: {},
    extraReducers: {
        [getCameras.pending]: (state) => {
            state.loading = true;
        },
        [getCameras.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
        },
        [getCameras.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        },

        [createCamera.pending]: (state) => {
            state.loading = true;
        },
        [createCamera.fulfilled]: (state) => {
            state.loading = false;
        },
        [createCamera.rejected]: (state) => {
            state.loading = false;
        },

        [updateCamera.pending]: (state) => {
            state.loading = true;
        },
        [updateCamera.fulfilled]: (state) => {
            state.loading = false;
        },
        [updateCamera.rejected]: (state) => {
            state.loading = false;
        },

        [deleteCamera.pending]: (state) => {
            state.loading = true;
        },
        [deleteCamera.fulfilled]: (state) => {
            state.loading = false;
        },
        [deleteCamera.rejected]: (state) => {
            state.loading = false;
        },

        [resetCamera.pending]: (state) => {
            state.loading = true;
        },
        [resetCamera.fulfilled]: (state) => {
            state.loading = false;
        },
        [resetCamera.rejected]: (state) => {
            state.loading = false;
        }
    }
});
