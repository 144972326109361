import { createAsyncThunk } from '@reduxjs/toolkit';
import { SERVICE_API } from 'common/constants';
import { API } from 'services';

export const getServices = createAsyncThunk(
    'service/getServices',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.get(SERVICE_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const createService = createAsyncThunk(
    'service/createService',
    async (item, { rejectWithValue }) => {
        try {
            const { data } = await API.post(SERVICE_API, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const updateService = createAsyncThunk(
    'service/updateService',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${SERVICE_API}/${id}`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const deleteService = createAsyncThunk(
    'service/deleteService',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.delete(`${SERVICE_API}/${id}`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
