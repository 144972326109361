export const PRODUCTION_FISH_NAMES = [
    {
        id: 'laks',
        text: 'general.fish.laks'
    },
    {
        id: 'ørret',
        text: 'general.fish.ørret'
    }
];
