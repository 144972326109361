import { createAsyncThunk } from '@reduxjs/toolkit';
import { FISHERY_BOAT_API } from 'common/constants';
import { API } from 'services';

export const getFisheryBoats = createAsyncThunk(
    'fisheryBoats/getFisheryBoats',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.get(FISHERY_BOAT_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const createFisheryBoat = createAsyncThunk(
    'fisheryBoats/createFisheryBoat',
    async (item, { rejectWithValue }) => {
        try {
            const { data } = await API.post(FISHERY_BOAT_API, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const updateFisheryBoat = createAsyncThunk(
    'fisheryBoats/updateFisheryBoat',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${FISHERY_BOAT_API}/${id}`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const deleteFisheryBoat = createAsyncThunk(
    'fisheryBoats/deleteFisheryBoat',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.delete(`${FISHERY_BOAT_API}/${id}`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const restoreFisheryBoat = createAsyncThunk(
    'fisheryBoats/restoreFisheryBoat',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${FISHERY_BOAT_API}/restore`, { id });
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
