import { DribbbleOutlined } from '@ant-design/icons';
import { Button, Modal, Spin } from 'antd';
import { FDTree } from 'common/components';
import { SUCCESSFULLY_RESTORE_MESSAGE } from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getSites, getSitesContainingPens, restoreSite } from 'redux/thunks';
import './RestoreSite.scss';

const RestoreSite = ({ open = false, site, onClose = () => {} }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [checkedKeys, setCheckedKeys] = useState([]);
    const upsertLoading = useSelector((s) => s.site.loading || false);

    const treeData = site?.pens?.map((pen) => ({
        title: pen.penNumber,
        key: pen.penNumber,
        icon: <DribbbleOutlined />
    }));

    useEffect(() => {
        setCheckedKeys([]);
    }, [site]);

    const handleCheck = (checkedKeysValue) => setCheckedKeys(checkedKeysValue);

    const handleRestoreSite = async () => {
        try {
            const request = {
                id: site.id,
                penNumbers: checkedKeys.filter((key) => site.id !== key)
            };

            await dispatch(restoreSite(request)).unwrap();
            dispatch(getSitesContainingPens());
            dispatch(getSites({ isDeleted: true }));

            alertSuccessMessage(t(SUCCESSFULLY_RESTORE_MESSAGE));

            onClose();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    return (
        <Modal
            title={`${t('site.restoreSite.title')} ${site?.siteName}`}
            open={open}
            onCancel={onClose}
            onOk={onClose}
            footer={
                <>
                    <Button type="default" onClick={onClose} className="m-1">
                        {t('site.restoreSite.cancel')}
                    </Button>

                    <Button
                        onClick={handleRestoreSite}
                        className="m-1 bg-sky-700 hover:bg-sky-800 text-white hover:text-white"
                    >
                        {t('site.restoreSite.restore')}
                    </Button>
                </>
            }
            width={350}
            className="restore-site"
        >
            <Spin spinning={upsertLoading}>
                <div className="font-medium text-base mb-2">{t('site.restoreSite.selectPens')}</div>
                <FDTree
                    className="tree overflow-y-auto font-medium"
                    onCheck={handleCheck}
                    checkedKeys={checkedKeys}
                    treeData={treeData}
                />
            </Spin>
        </Modal>
    );
};

RestoreSite.propTypes = {
    open: PropTypes.bool,
    company: PropTypes.object,
    onClose: PropTypes.func
};

export default RestoreSite;
