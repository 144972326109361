import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    COMPANIES_SELECTION_API,
    COMPANY_TYPES_SELECTION_API,
    ENGINE_FISHERY_BOAT_SELECTION_API,
    ENGINE_SELECTION_API,
    ENGINE_SERVICE_BOAT_SELECTION_API,
    ENGINE_SITE_SELECTION_API,
    ORIGINAL_BOATS_SELECTION_API,
    ORIGINAL_SITES_SELECTION_API,
    PEN_SELECTION_API,
    SERVICES_SELECTION_API,
    SERVICE_BOATS_SELECTION_API,
    SITES_SELECTION_API,
    TENANT_TYPES_SELECTION_API
} from 'common/constants';
import { API } from 'services';

export const getCompaniesSelection = createAsyncThunk(
    'selections/companiesSelection',
    async ({ isFilterByAzureTenantId = true, companyTypes = [] }, { rejectWithValue }) => {
        const filters = {
            companyTypes,
            azureTenantId: isFilterByAzureTenantId ? localStorage.getItem('tenantId') : undefined
        };

        try {
            const { data } = await API.get(COMPANIES_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getCompanyTypesSelection = createAsyncThunk(
    'selections/companyTypesSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(COMPANY_TYPES_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getEngineFisheryBoatSelection = createAsyncThunk(
    'selections/engineFisheryBoatSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(ENGINE_FISHERY_BOAT_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getEngineServiceBoatSelection = createAsyncThunk(
    'selections/engineServiceBoatSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(ENGINE_SERVICE_BOAT_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getEngineSiteSelection = createAsyncThunk(
    'selections/engineSiteSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(ENGINE_SITE_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getOriginalSitesSelection = createAsyncThunk(
    'selections/originalSitesSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(ORIGINAL_SITES_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getOriginalBoatsSelection = createAsyncThunk(
    'selections/originalBoatsSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(ORIGINAL_BOATS_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getServiceBoatsSelection = createAsyncThunk(
    'selections/serviceBoatsSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(SERVICE_BOATS_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getServicesSelection = createAsyncThunk(
    'selections/servicesSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(SERVICES_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getSitesSelection = createAsyncThunk(
    'selections/sitesSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(SITES_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getPensSelection = createAsyncThunk(
    'selections/getPensSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(PEN_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getTenantTypesSelection = createAsyncThunk(
    'selections/tenantTypesSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(TENANT_TYPES_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getEnginesSelection = createAsyncThunk(
    'selections/getEnginesSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(ENGINE_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
