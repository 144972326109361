import { createSlice } from '@reduxjs/toolkit';
import { getServices, createService, updateService, deleteService } from 'redux/thunks/serviceThunk';

const initialState = {
    data: [],
    loading: false
};

export const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {},
    extraReducers: {
        [getServices.pending]: (state) => {
            state.loading = true;
        },
        [getServices.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        [getServices.rejected]: (state) => {
            state.loading = false;
        },

        [createService.pending]: (state) => {
            state.loading = true;
        },
        [createService.fulfilled]: (state) => {
            state.loading = false;
        },
        [createService.rejected]: (state) => {
            state.loading = false;
        },

        [updateService.pending]: (state) => {
            state.loading = true;
        },
        [updateService.fulfilled]: (state) => {
            state.loading = false;
        },
        [updateService.rejected]: (state) => {
            state.loading = false;
        },

        [deleteService.pending]: (state) => {
            state.loading = true;
        },
        [deleteService.fulfilled]: (state) => {
            state.loading = false;
        },
        [deleteService.rejected]: (state) => {
            state.loading = false;
        }
    }
});
