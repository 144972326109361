import { Spin } from 'antd';
import fishwellLogo from 'assets/Fishwell_Technology_Logo.png';
import { INTERNAL_SERVER } from 'common/constants';
import { alertErrorMessage } from 'common/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getHeaderTenants, updateCurrentTenantId } from 'redux/thunks';

const TenantHandling = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { navigateUrl } = location.state || { navigatedUrl: '/' };

    const { data: tenantInfo } = useSelector((s) => s.headerTenant);

    useEffect(() => {
        const getTenantList = async () => {
            try {
                if (!tenantInfo.tenants.length) {
                    const tenants = await dispatch(getHeaderTenants()).unwrap();
                    if (!tenants.length) {
                        alertErrorMessage(INTERNAL_SERVER, t);
                    }
                }
            } catch (error) {
                alertErrorMessage(error, t);
            }
        };

        getTenantList();
    }, []);

    useEffect(() => {
        if (!tenantInfo.tenants?.length) {
            return;
        }

        const savedTenantId = localStorage.getItem('tenantId');
        const tenant =
            tenantInfo.tenants.find((tenant) => tenant.id === savedTenantId) ||
            tenantInfo.tenants.find((tenant) => tenant.isDefault) ||
            tenantInfo.tenants[0];

        // update tenant id to store and local storage
        dispatch(updateCurrentTenantId(tenant.id));
        if (tenant.id !== savedTenantId) {
            localStorage.setItem('tenantId', tenant.id);
        }
    }, [tenantInfo.tenants]);

    useEffect(() => {
        if (!tenantInfo.currentTenantId) {
            return;
        }

        // show this page for 1 second before navigating to the another page to improve the UX
        const navigateTimeout = setTimeout(() => {
            navigate(navigateUrl);
        }, 1000);

        return () => {
            clearTimeout(navigateTimeout);
        };
    }, [tenantInfo.currentTenantId]);

    return (
        <div className="min-h-[100vh] bg-white flex flex-col justify-center items-center">
            <img src={fishwellLogo} alt="Fishwell Technology Logo" className="w-72" />
            <Spin></Spin>
        </div>
    );
};

export default TenantHandling;
