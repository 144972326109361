export const TENANT_HANDLING_PAGE = '/tenant-handling';
export const FORBIDDEN_PAGE = '/forbidden';
export const NOT_ALLOWED_PAGE = '/not-allowed';
export const UNAUTHORIZED_PAGE = '/unauthorized';
export const SESSION_EXPIRED_PAGE = '/session-expired';

export const DASHBOARD_PAGE = '/';
export const TENANT_PAGE = '/tenant';
export const USER_PAGE = '/user';
export const COMPANY_PAGE = '/company';
export const SITE_PAGE = '/site';
export const SERVICE_BOAT_PAGE = '/service-boat';
export const SERVICE_PAGE = '/service';
export const PROFILE_PAGE = '/profile';
export const PEN_PAGE = '/pen';
export const FISHERY_BOAT_PAGE = '/fishery-boat';
export const ENGINE_PAGE = '/engine';
export const FISH_GROUP_PAGE = '/fishwell-fish-id';
export const CAMERA_PAGE = '/camera';
export const PROCESS_PAGE = '/process';
export const NOT_FOUND_PAGE = '*';
