import { RollbackOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { FDTable } from 'common/components';
import { BUTTON_GRAY_CLASS, Title } from 'common/constants';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FilterBar from './FilterBar';
import { TABLE_COLUMNS } from './ProcessColumn';
import ProcessForm from './ProcessForm';

const Process = () => {
    const { t } = useTranslation();

    const { data: processes, loading } = useSelector((s) => s.ftProcess);

    const [modalState, setModalState] = useState({
        open: false,
        process: null
    });

    const handleShowModal = (process) => {
        setModalState({
            open: true,
            process
        });
    };

    const handleClose = () => {
        setModalState({
            open: false,
            process: null
        });
    };

    return (
        <>
            <Title level={4}>{t('process.title')}</Title>

            <FilterBar />

            <FDTable
                loading={loading}
                headers={[
                    TABLE_COLUMNS.FT_PROCESS,
                    TABLE_COLUMNS.FLS_PROCESS,
                    TABLE_COLUMNS.FARMER_COMPANY_NAME,
                    TABLE_COLUMNS.SITE_NAME,
                    TABLE_COLUMNS.PEN_NUMBER,
                    TABLE_COLUMNS.BOAT_NAME,
                    {
                        dataIndex: 'action',
                        className: 'action',
                        title: 'process.processTable.action',
                        render: (_, record) => (
                            <Space size="middle">
                                <Button
                                    className={`restore-site ${BUTTON_GRAY_CLASS}`}
                                    onClick={() => handleShowModal(record)}
                                >
                                    <RollbackOutlined />
                                    <span>{t('process.processTable.link')}</span>
                                </Button>
                            </Space>
                        )
                    }
                ]}
                data={processes}
            />

            <ProcessForm
                process={modalState.process}
                open={modalState.open}
                onClose={handleClose}
            />
        </>
    );
};

export default Process;
