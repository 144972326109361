import { EventSourcePolyfill } from 'event-source-polyfill';
import { getToken } from './Auth';

export const resetCamera = async (url) => {
    if (!url) return;

    const token = await getToken();

    const headers = {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'text/event-stream'
    };

    return new EventSourcePolyfill(url, { headers });
};
