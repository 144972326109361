import { Button, Form, Input, Modal, Spin } from 'antd';
import { FDSelection } from 'common/components';
import {
    SUCCESSFULLY_CREATION_MESSAGE,
    SUCCESSFULLY_EDIT_MESSAGE,
    TextArea
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createCamera, updateCamera } from 'redux/thunks';

const CameraForm = ({ open = false, camera, onClose = () => {}, onReload = () => {} }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const upsertLoading = useSelector((s) => s.camera.loading);
    const enginesSelection = useSelector((s) => s.enginesSelection.data);

    const isUpdateForm = !!camera;

    useEffect(() => {
        if (isUpdateForm) {
            // format configuration json
            const configurationObj = JSON.parse(camera.configurationJson);
            form.setFieldsValue({
                ...camera,
                configurationJson: JSON.stringify(configurationObj, null, 2)
            });
        } else {
            form.resetFields();
        }
    }, [camera]);

    const createOrUpdateCamera = (req) => {
        if (isUpdateForm) {
            handleUpdateCamera(req);
        } else {
            handleCreateCamera(req);
        }
    };

    const handleCreateCamera = async (req) => {
        try {
            await dispatch(createCamera(req)).unwrap();

            alertSuccessMessage(t(SUCCESSFULLY_CREATION_MESSAGE));
            onClose();
            onReload();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const handleUpdateCamera = async (req) => {
        try {
            await dispatch(updateCamera({ id: camera.id, item: req })).unwrap();

            alertSuccessMessage(t(SUCCESSFULLY_EDIT_MESSAGE));
            onClose();
            onReload();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <div>
            <Modal
                title={
                    isUpdateForm
                        ? t('camera.cameraForm.updateCamera')
                        : t('camera.cameraForm.createCamera')
                }
                open={open}
                onCancel={handleCancel}
                onOk={onClose}
                width={800}
                footer={
                    <>
                        <Button type="default" onClick={handleCancel} className="m-1">
                            {t('general.form.cancel')}
                        </Button>
                        <Button
                            onClick={form.submit}
                            className="m-1 bg-sky-700 hover:bg-sky-800 text-white hover:text-white"
                        >
                            {isUpdateForm ? t('general.form.saveChange') : t('general.form.create')}
                        </Button>
                    </>
                }
            >
                <Spin spinning={upsertLoading}>
                    <Form
                        name="basic"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 16 }}
                        onFinish={createOrUpdateCamera}
                        autoComplete="off"
                        form={form}
                        initialValues={{
                            configurationJson: '{}'
                        }}
                    >
                        <Form.Item
                            name="cameraId"
                            label={t('camera.cameraForm.cameraId.title')}
                            rules={[
                                {
                                    required: true,
                                    message: t('camera.cameraForm.cameraId.required')
                                }
                            ]}
                        >
                            <Input
                                placeholder={t('camera.cameraForm.cameraId.placeholder')}
                                disabled={isUpdateForm}
                            />
                        </Form.Item>

                        <Form.Item
                            name="name"
                            label={t('camera.cameraForm.name.title')}
                            rules={[
                                {
                                    required: true,
                                    message: t('camera.cameraForm.name.required')
                                }
                            ]}
                        >
                            <Input placeholder={t('camera.cameraForm.name.placeholder')} />
                        </Form.Item>

                        <Form.Item
                            name="description"
                            label={t('camera.cameraForm.description.title')}
                        >
                            <Input placeholder={t('camera.cameraForm.description.placeholder')} />
                        </Form.Item>

                        <Form.Item
                            label={t('camera.cameraForm.engineId.title')}
                            name="engineId"
                            rules={[
                                {
                                    required: true,
                                    message: t('camera.cameraForm.engineId.required')
                                }
                            ]}
                        >
                            <FDSelection
                                listSelectItem={enginesSelection}
                                placeholder={t('camera.cameraForm.engineId.placeholder')}
                                disabled={isUpdateForm}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t('camera.cameraForm.configurationJson.title')}
                            name="configurationJson"
                            rules={[
                                {
                                    required: true,
                                    message: t('camera.cameraForm.configurationJson.required')
                                }
                            ]}
                        >
                            <TextArea
                                rows={15}
                                placeholder={t('camera.cameraForm.configurationJson.placeholder')}
                            />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </div>
    );
};

export default CameraForm;
