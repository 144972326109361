import { loginRequest, msalInstance } from 'authConfig';
import { SESSION_EXPIRED_PAGE } from 'common/constants';

export const getToken = async () => {
    try {
        const response = await msalInstance.acquireTokenSilent({
            account: msalInstance.getAllAccounts()[0],
            scopes: loginRequest.scopes
        });
        return response.idToken;
    } catch (error) {
        window.location.href = SESSION_EXPIRED_PAGE;
    }
};
