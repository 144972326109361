import { createSlice } from '@reduxjs/toolkit';
import { getCompaniesSelection } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const companySelectionSlice = createSlice({
    name: 'companySelection',
    initialState,
    reducers: {},
    extraReducers: {
        [getCompaniesSelection.pending]: (state) => {
            state.loading = true;
        },
        [getCompaniesSelection.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        [getCompaniesSelection.rejected]: (state) => {
            state.loading = false;
        }
    }
});
