import { Result, Button } from 'antd';
import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
const FDGlobalBoundaryError = ({ error, resetErrorBoundary }) => {
	const location = useLocation();
	const errorLocation = useRef(location.pathname);

	useEffect(() => {
		if (location.pathname !== errorLocation.current) {
			resetErrorBoundary();
		}
	}, [location.pathname, resetErrorBoundary]);

	const reset = () => {
		resetErrorBoundary();
		window.location.href = '/';
	};

	return (
		<Result
			status='warning'
			title='There are some problems with your operation.'
			subTitle={error?.message}
			extra={
				<Button type='primary' key='console' onClick={reset}>
					Go Back
				</Button>
			}
		/>
	);
};

export default FDGlobalBoundaryError;
