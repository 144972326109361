import {
    DeleteOutlined,
    EditOutlined,
    GlobalOutlined,
    PlusOutlined,
    RestOutlined,
    RollbackOutlined
} from '@ant-design/icons';
import { Button, Col, Row, Space, Spin, Tabs } from 'antd';
import { FDTable } from 'common/components';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_GRAY_CLASS,
    BUTTON_GREEN_CLASS,
    SUCCESSFULLY_DELETION_MESSAGE,
    SUCCESSFULLY_RESTORE_MESSAGE,
    Title
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import NotFound from 'pages/not-found';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFisheryBoat, getFisheryBoats, restoreFisheryBoat } from 'redux/thunks';
import Swal from 'sweetalert2';
import FisheryBoatForm from './FisheryBoatForm';

const FisheryBoat = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { canManageFisheryBoat } = useFlags();

    const { data: boats = [], loading = false } = useSelector((s) => s.fisheryBoat || {});

    const [isDeleteTab, setIsDeleteTab] = useState(false);
    const [modalState, setModalState] = useState({ open: false, boat: null });

    const columns = [
        {
            dataIndex: 'name',
            title: 'fisheryBoat.fisheryBoatTable.name',
            sortDirections: ['ascend', 'descend'],
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
                multiple: 1
            },
            render: (_, record) => (
                <div>
                    <Title level={5}>{record.name}</Title>
                    <p>{record.ownerCompany.name || 'Company owner'}</p>
                </div>
            )
        },
        {
            dataIndex: 'description',
            title: 'fisheryBoat.fisheryBoatTable.description'
        },
        {
            dataIndex: 'externalBoatId',
            title: 'fisheryBoat.fisheryBoatTable.externalBoatId'
        },
        {
            dataIndex: 'action',
            title: 'fisheryBoat.fisheryBoatTable.action',
            render: (_, record) => (
                <Space size="middle">
                    {isDeleteTab ? (
                        <Button
                            className={BUTTON_GRAY_CLASS}
                            onClick={() => handleRestoreBoat(record.id)}
                        >
                            <RollbackOutlined />
                            <span>{t('general.action.restore')}</span>
                        </Button>
                    ) : (
                        <>
                            <Button
                                className={BUTTON_GRAY_CLASS}
                                onClick={() => handleShowModal(record)}
                            >
                                <EditOutlined />
                                <span>{t('general.action.edit')}</span>
                            </Button>
                            <Button
                                className={BUTTON_GREEN_CLASS}
                                onClick={() => handleDeleteBoat(record.id)}
                            >
                                <DeleteOutlined />
                                <span>{t('general.action.delete')}</span>
                            </Button>
                        </>
                    )}
                </Space>
            )
        }
    ];

    useEffect(() => {
        dispatch(getFisheryBoats({ isDeleted: isDeleteTab }));
    }, [isDeleteTab]);

    const handleTabChange = (value) => setIsDeleteTab(value);

    const handleReload = () => setIsDeleteTab(false);

    const handleClose = () => setModalState({ open: false, boat: null });

    const handleShowModal = (boat) => setModalState({ open: true, boat });

    const handleDeleteBoat = (id = null) => {
        Swal.fire({
            title: t('general.action.deleteModal.title'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0369a1',
            cancelButtonColor: '#64748b',
            confirmButtonText: t('general.action.deleteModalWithoutRevert.confirmText'),
            cancelButtonText: t('general.action.deleteModal.cancelText')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(deleteFisheryBoat(id)).unwrap();

                    dispatch(getFisheryBoats({ isDeleted: isDeleteTab }));
                    alertSuccessMessage(t(SUCCESSFULLY_DELETION_MESSAGE));
                } catch (err) {
                    alertErrorMessage(err, t);
                }
            }
        });
    };

    const handleRestoreBoat = (id = null) => {
        Swal.fire({
            title: t('general.action.restoreModal.title'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0369a1',
            cancelButtonColor: '#64748b',
            confirmButtonText: t('general.action.restoreModal.confirmText'),
            cancelButtonText: t('general.action.deleteModal.cancelText')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(restoreFisheryBoat(id)).unwrap();

                    dispatch(getFisheryBoats({ isDeleted: isDeleteTab }));
                    alertSuccessMessage(t(SUCCESSFULLY_RESTORE_MESSAGE));
                } catch (err) {
                    alertErrorMessage(err, t);
                }
            }
        });
    };

    if (!canManageFisheryBoat) return <NotFound />;

    return (
        <>
            <Title level={4}>{t('fisheryBoat.title')}</Title>
            <Row justify="center">
                <Col span={24}>
                    <Button className={BUTTON_BLUE_CLASS} onClick={() => handleShowModal(null)}>
                        <PlusOutlined />
                        <span>{t('fisheryBoat.newFisheryBoatBtn')}</span>
                    </Button>

                    <Spin spinning={loading}>
                        <Tabs
                            activeKey={isDeleteTab}
                            animated={true}
                            onChange={handleTabChange}
                            items={[
                                {
                                    label: (
                                        <span>
                                            <GlobalOutlined />
                                            <span>{t('fisheryBoat.activeFisheryBoats')}</span>
                                        </span>
                                    ),
                                    key: false,
                                    children: (
                                        <FDTable headers={columns} data={boats} rowKey="id" />
                                    ),
                                    disabled: loading
                                },
                                {
                                    label: (
                                        <span>
                                            <RestOutlined />
                                            <span>{t('fisheryBoat.deletedFisheryBoats')}</span>
                                        </span>
                                    ),
                                    key: true,
                                    children: (
                                        <FDTable headers={columns} data={boats} rowKey="id" />
                                    ),
                                    disabled: loading
                                }
                            ]}
                        />
                    </Spin>
                </Col>
            </Row>

            <FisheryBoatForm
                open={modalState.open}
                boat={modalState.boat}
                onClose={handleClose}
                onReload={handleReload}
            />
        </>
    );
};

export default FisheryBoat;
