import { Title } from 'common/constants';
import { FDGeneralInfo } from 'components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentProfile } from 'redux/thunks';

const GeneralInfo = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { data: profile = {}, loading = false } = useSelector((s) => s.profile);

    useEffect(() => {
        dispatch(getCurrentProfile());
    }, []);

    const parseToInfoList = () => {
        return [
            {
                key: t('dashboard.generalInformation.companyName'),
                value: profile.company?.name
            },
            {
                key: t('dashboard.generalInformation.name'),
                value: profile.name
            },
            {
                key: t('dashboard.generalInformation.userRole'),
                value: profile.roles
            }
        ];
    };

    return (
        <div>
            <Title level={4}>{t('dashboard.generalInformation.title')}</Title>
            <FDGeneralInfo
                loading={loading}
                logo={profile.company?.logoUrl}
                infoList={parseToInfoList()}
            />
        </div>
    );
};

export default GeneralInfo;
