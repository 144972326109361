import {
    ApartmentOutlined,
    AppstoreAddOutlined,
    BoxPlotOutlined,
    CloudOutlined,
    DribbbleOutlined,
    GroupOutlined,
    HomeOutlined,
    LinkOutlined,
    OneToOneOutlined,
    PicCenterOutlined,
    ShopOutlined,
    UsergroupAddOutlined,
    VideoCameraOutlined
} from '@ant-design/icons';
import { useIsAuthenticated } from '@azure/msal-react';
import { Layout as LibLayout, Menu } from 'antd';
import {
    CAMERA_PAGE,
    COMPANY_PAGE,
    DASHBOARD_PAGE,
    ENGINE_PAGE,
    FISHERY_BOAT_PAGE,
    FISH_GROUP_PAGE,
    PEN_PAGE,
    PROCESS_PAGE,
    SERVICE_BOAT_PAGE,
    SERVICE_PAGE,
    SITE_PAGE,
    TENANT_PAGE,
    USER_PAGE
} from 'common/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router-dom';
import i18n from 'translation/i18n';
import './Sidebar.scss';

const Sidebar = () => {
    const { t } = useTranslation();
    const { Sider } = LibLayout;
    const [collapsed] = useState(false);
    const isAuthenticated = useIsAuthenticated();

    const location = useLocation();
    const { canManageFisheryBoat } = useFlags();

    const menu = useMemo(() => {
        if (!isAuthenticated) return [];
        return [
            {
                icon: <HomeOutlined />,
                title: <span>{t('layout.sidebar.dashboard')}</span>,
                path: DASHBOARD_PAGE
            },
            {
                icon: <CloudOutlined />,
                title: <span>{t('layout.sidebar.tenant')}</span>,
                path: TENANT_PAGE
            },
            {
                icon: <ShopOutlined />,
                title: <span>{t('layout.sidebar.company')}</span>,
                path: COMPANY_PAGE
            },
            {
                icon: <UsergroupAddOutlined />,
                title: <span>{t('layout.sidebar.user')}</span>,
                path: USER_PAGE
            },
            {
                icon: <GroupOutlined />,
                title: <span>{t('layout.sidebar.site')}</span>,
                path: SITE_PAGE
            },
            {
                icon: <DribbbleOutlined />,
                title: <span>{t('layout.sidebar.pen')}</span>,
                path: PEN_PAGE
            },
            {
                icon: <PicCenterOutlined />,
                title: <span>{t('layout.sidebar.serviceBoat')}</span>,
                path: SERVICE_BOAT_PAGE
            },
            {
                icon: <OneToOneOutlined />,
                title: <span>{t('layout.sidebar.fisheryBoat')}</span>,
                path: FISHERY_BOAT_PAGE,
                hidden: !canManageFisheryBoat
            },
            {
                icon: <AppstoreAddOutlined />,
                title: <span>{t('layout.sidebar.service')}</span>,
                path: SERVICE_PAGE
            },
            {
                icon: <BoxPlotOutlined />,
                title: <span>{t('layout.sidebar.engine')}</span>,
                path: ENGINE_PAGE
            },
            {
                icon: <ApartmentOutlined />,
                title: <span>{t('layout.sidebar.fishGroup')}</span>,
                path: FISH_GROUP_PAGE
            },
            {
                icon: <VideoCameraOutlined />,
                title: <span>{t('layout.sidebar.camera')}</span>,
                path: CAMERA_PAGE
            },
            {
                icon: <LinkOutlined />,
                title: <span>{t('layout.sidebar.process')}</span>,
                path: PROCESS_PAGE
            }
        ];
    }, [isAuthenticated, i18n.language, canManageFisheryBoat]);

    return (
        <Sider trigger={null} className="sidebar" collapsible collapsed={collapsed} width="250">
            <Link to="/">
                <div className={`brand-name ${collapsed && 'collapsed'}`}>
                    <div className="logo" />
                </div>
            </Link>

            <Menu
                theme="light"
                className="menu"
                selectedKeys={[location.pathname]}
                defaultSelectedKeys={['/']}
                mode="inline"
            >
                {menu.map((menuItem) => (
                    <Menu.Item
                        key={menuItem.path}
                        className={`item ${menuItem.className}`}
                        hidden={menuItem.hidden}
                    >
                        <NavLink to={menuItem.path} className="flex items-center">
                            {menuItem.icon}
                            <span className="title">{menuItem.title}</span>
                        </NavLink>
                    </Menu.Item>
                ))}
            </Menu>
        </Sider>
    );
};

export default Sidebar;
