import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Space } from 'antd';
import defaultLogo from 'assets/Default_Logo.png';
import { FDTable } from 'common/components';
import { BUTTON_BLUE_CLASS, BUTTON_GRAY_CLASS, Title } from 'common/constants';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanies } from 'redux/thunks';
import CompanyForm from './CompanyForm';

const Company = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { data: companies = [], loading = false } = useSelector((s) => s.company || {});

    const [modalState, setModalState] = useState({ open: false, company: null });

    const columns = [
        {
            title: 'company.companyTable.company',
            dataIndex: 'name',
            key: 'name',
            sortDirections: ['ascend', 'descend'],
            render: (_, record) => (
                <Avatar.Group>
                    <img
                        className="object-contain rounded-full"
                        src={record.logoUrl || defaultLogo}
                        width="60vw"
                        height="60vh"
                        alt="Company logo"
                    />
                    <div className="avatar-info ml-2">
                        <Title level={5}>{record.name}</Title>
                        <p>{record.email}</p>
                    </div>
                </Avatar.Group>
            )
        },
        {
            title: 'company.companyTable.organizationId',
            dataIndex: 'organizationId',
            key: 'organizationId'
        },
        {
            title: 'company.companyTable.types',
            dataIndex: 'companyTypeNames',
            key: 'companyTypeNames',
            render: (_, record) =>
                record.companyTypeNames?.map((type, index) => (
                    <p className="my-1" key={index}>
                        {type}
                    </p>
                ))
        },
        {
            title: 'company.companyTable.description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'company.companyTable.phoneNumber',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber'
        },
        {
            title: 'company.companyTable.address',
            dataIndex: 'address',
            key: 'address'
        },
        {
            title: 'company.companyTable.action',
            dataIndex: 'Action',
            render: (_, record) => (
                <Space size="middle">
                    <Button className={BUTTON_GRAY_CLASS} onClick={() => handleShowModal(record)}>
                        <EditOutlined />
                        <span>{t('company.companyTable.edit')}</span>
                    </Button>
                </Space>
            )
        }
    ];

    useEffect(() => {
        dispatch(getCompanies());
    }, []);

    const handleClose = () => setModalState({ open: false, company: null });

    const handleShowModal = (company) => setModalState({ open: true, company });

    return (
        <>
            <Title level={4}>{t('company.title')}</Title>
            <div>
                <Button className={BUTTON_BLUE_CLASS} onClick={() => handleShowModal(null)}>
                    <PlusOutlined />
                    <span>{t('company.companyForm.buttonTitle')}</span>
                </Button>

                <FDTable headers={columns} data={companies} loading={loading} rowKey="id" />
            </div>

            <CompanyForm
                open={modalState.open}
                company={modalState.company}
                onClose={handleClose}
            />
        </>
    );
};

export default Company;
