import { createSlice } from '@reduxjs/toolkit';
import {
    addFishGroup,
    deleteFishGroup,
    divideFishGroup,
    getFishGroups,
    moveFishGroup,
    releaseFishGroup,
    updateFishGroup
} from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const fishGroupSlice = createSlice({
    name: 'fishGroup',
    initialState,
    reducers: {},
    extraReducers: {
        [getFishGroups.pending]: (state) => {
            state.loading = true;
        },
        [getFishGroups.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
        },
        [getFishGroups.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        },

        [addFishGroup.pending]: (state) => {
            state.loading = true;
        },
        [addFishGroup.fulfilled]: (state) => {
            state.loading = false;
        },
        [addFishGroup.rejected]: (state) => {
            state.loading = false;
        },

        [updateFishGroup.pending]: (state) => {
            state.loading = true;
        },
        [updateFishGroup.fulfilled]: (state) => {
            state.loading = false;
        },
        [updateFishGroup.rejected]: (state) => {
            state.loading = false;
        },

        [divideFishGroup.pending]: (state) => {
            state.loading = true;
        },
        [divideFishGroup.fulfilled]: (state) => {
            state.loading = false;
        },
        [divideFishGroup.rejected]: (state) => {
            state.loading = false;
        },

        [moveFishGroup.pending]: (state) => {
            state.loading = true;
        },
        [moveFishGroup.fulfilled]: (state) => {
            state.loading = false;
        },
        [moveFishGroup.rejected]: (state) => {
            state.loading = false;
        },

        [releaseFishGroup.pending]: (state) => {
            state.loading = true;
        },
        [releaseFishGroup.fulfilled]: (state) => {
            state.loading = false;
        },
        [releaseFishGroup.rejected]: (state) => {
            state.loading = false;
        },

        [deleteFishGroup.pending]: (state) => {
            state.loading = true;
        },
        [deleteFishGroup.fulfilled]: (state) => {
            state.loading = false;
        },
        [deleteFishGroup.rejected]: (state) => {
            state.loading = false;
        }
    }
});
