import { createAsyncThunk } from '@reduxjs/toolkit';
import { TENANT_API } from 'common/constants';
import { API } from 'services';

export const getTenants = createAsyncThunk(
    'tenant/getTenants',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.get(TENANT_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const createTenant = createAsyncThunk(
    'tenant/createTenant',
    async (item, { rejectWithValue }) => {
        try {
            const { data } = await API.post(TENANT_API, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
