import { createSlice } from '@reduxjs/toolkit';
import { createCompany, getCompanies, updateCompany } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {},
    extraReducers: {
        [getCompanies.pending]: (state) => {
            state.loading = true;
        },
        [getCompanies.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        [getCompanies.rejected]: (state) => {
            state.loading = false;
        },

        [createCompany.pending]: (state) => {
            state.loading = true;
        },
        [createCompany.fulfilled]: (state) => {
            state.loading = false;
        },
        [createCompany.rejected]: (state) => {
            state.loading = false;
        },

        [updateCompany.pending]: (state) => {
            state.loading = true;
        },
        [updateCompany.fulfilled]: (state) => {
            state.loading = false;
        },
        [updateCompany.rejected]: (state) => {
            state.loading = false;
        }
    }
});
