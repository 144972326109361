import { createSlice } from '@reduxjs/toolkit';
import {
   createPen,
   getPens,
   updatePen,
   deletePen,
   restorePen
} from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const penSlice = createSlice({
    name: 'pen',
    initialState,
    reducers: {},
    extraReducers: {
        [getPens.pending]: (state) => {
            state.loading = true;
        },
        [getPens.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        [getPens.rejected]: (state) => {
            state.loading = false;
        },

        [createPen.pending]: (state) => {
            state.loading = true;
        },
        [createPen.fulfilled]: (state) => {
            state.loading = false;
        },
        [createPen.rejected]: (state) => {
            state.loading = false;
        },

        [updatePen.pending]: (state) => {
            state.loading = true;
        },
        [updatePen.fulfilled]: (state) => {
            state.loading = false;
        },
        [updatePen.rejected]: (state) => {
            state.loading = false;
        },

        [deletePen.pending]: (state) => {
            state.loading = true;
        },
        [deletePen.fulfilled]: (state) => {
            state.loading = false;
        },
        [deletePen.rejected]: (state) => {
            state.loading = false;
        },

        [restorePen.pending]: (state) => {
            state.loading = true;
        },
        [restorePen.fulfilled]: (state) => {
            state.loading = false;
        },
        [restorePen.rejected]: (state) => {
            state.loading = false;
        }
    }
});
