import { TENANT_HANDLING_PAGE } from 'common/constants';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const TenantRoute = ({ path = '/', children }) => {
    const navigate = useNavigate();

    const { data: tenantInfo = {} } = useSelector((s) => s.headerTenant);

    useEffect(() => {
        if (!tenantInfo.currentTenantId) {
            navigate(TENANT_HANDLING_PAGE, { state: { navigateUrl: path } });
        }
    }, []);

    return <>{children}</>;
};

TenantRoute.propTypes = {
    path: PropTypes.string,
    children: PropTypes.node
};

export default TenantRoute;
